import { Navigate, Route, Routes } from "react-router-dom";
import AddManuallyPage from "../app/dashboard/components/pages/AddManualyPage";
import DashboardRouterPage from "../app/dashboard/components/pages/DashboardRouerPage";
import LoginPage from "../app/dashboard/components/pages/LoginPage";
import { useEffect, useState } from "react";
import LandingPage from "../app/landingPage/components/index";
import DashboardPage from "../app/dashboard/components/pages/DashboardPage";
import SnackbarAlert from "../app/dashboard/components/utils/Snackbar";
import { SnackbarType } from "../app/dashboard/enums/SnackbarType";
import IUser from "../app/dashboard/models/IUser";
import jwt_decode from "jwt-decode";
import { FullLoading } from "../app/utils/components/loading/Loading";
import { NavigateTo } from "../app/dashboard/enums/NavigateTo";
import axios from "axios";
import DevIconComponents from "../app/utils/DevIconComponents";

export default function AppRoutes() {
  const [user, setUser] = useState<IUser | null>(
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user") || "")
      : null
  );


  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      const token: any = sessionStorage.getItem("token");
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      let decode: any = jwt_decode(token);
      setUser(decode.user);
    }
  }, []);

  const [snackbarState, setSnackbarState] = useState<{
    open: boolean;
    type: SnackbarType;
    message: string;
  }>({ open: false, type: SnackbarType.info, message: "" });
  const [loadingState, setLoadingState] = useState<{
    open: boolean;
    message: string;
  }>({ open: false, message: "" });

  function setSnackbar({
    open,
    type,
    message,
  }: {
    open: boolean;
    type?: SnackbarType;
    message?: string;
  }) {
    if (open && message && type) {
      setSnackbarState({ open, type, message });
    } else if (message) {
      setSnackbarState({
        open: true,
        type: snackbarState.type,
        message: message,
      });
    } else {
      setSnackbarState({
        open: false,
        type: snackbarState.type,
        message: snackbarState.message,
      });
    }
  }

  function setLoading({ open, message }: { open?: boolean; message?: string }) {
    if (open && message) {
      setLoadingState({ open: open, message: message });
    } else if (message) {
      setLoadingState({ open: true, message: message });
    } else {
      setLoadingState({
        open: open ? open : false,
        message: message ? message : "",
      });
    }
  }

  return (
    <div className="AppRoutes" style={{ height: "100vh" }} dir="rtl">
      <DevIconComponents />
      <FullLoading
        loadingState={loadingState}
        setLoading={setLoading}
        setSnackbar={setSnackbar}
      />
      <SnackbarAlert setSnackbar={setSnackbar} snackbarState={snackbarState} />
      <Routes>
        <Route
          path="/"
          element={<Navigate to={NavigateTo.REGISTRATION} />}
        ></Route>
        <Route
          path={NavigateTo.DASHBOARD}
          element={
            <DashboardPage
              setLoading={setLoading}
              setSnackbar={setSnackbar}
              user={user}
              setUser={setUser}
            />
          }
        ></Route>
        <Route
          path={NavigateTo.Login}
          element={
            <LoginPage
              setLoading={setLoading}
              setSnackbar={setSnackbar}
              setUser={setUser}
            />
          }
        ></Route>
        <Route
          path={NavigateTo.DASHBOARD_ROUTER}
          element={
            <DashboardRouterPage
              setLoading={setLoading}
              setSnackbar={setSnackbar}
              user={user}
            />
          }
        ></Route>
        <Route
          path={NavigateTo.ADD_MANUALLY}
          element={
            <AddManuallyPage
              setLoading={setLoading}
              setSnackbar={setSnackbar}
              user={user}
            />
          }
        ></Route>
        <Route
          path="/tickets-creator"
          element={
            <div style={{ backgroundColor: "red", height: "100vh" }}>
              landing page
            </div>
          }
        ></Route>
        <Route
          path={NavigateTo.REGISTRATION}
          element={<LandingPage setSnackbar={setSnackbar} />}
        ></Route>
      </Routes>
    </div>
  );
}
