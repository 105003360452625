import { LogoDevTwoTone } from '@mui/icons-material'
import { Alert, Snackbar } from '@mui/material'

export default function DevIconComponents() {


    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            sx={{  transform:"rotate(-22deg)", paddingTop:1, marginTop:-2.5, marginLeft:-6,display:"flex", alignItems:"center", justifyContent:"center"}}
            open={process.env.REACT_APP_ENVIRONMENT != "main"}
        >
            <Alert variant="outlined" sx={{width:"100%", borderRight:0,borderLeft:0,backdropFilter: "blur(22px)", paddingRight: 1, paddingLeft: 1, paddingTop: 0, gap: 0, paddingBottom: 0, boxShadow: 7, alignItems: "center", direction: "ltr", fontFamily: "Alef Hebrew, sans-serif" }} severity="warning" icon={false} >
                <div style={{paddingRight:"110px",paddingLeft:"60px", display: "flex", alignItems: "center", justifyContent: "center", gap: "2px" }}>
                    <div style={{ fontSize: "18px", fontWeight: "bold" }}>גירסת פיתוח</div>
                    <LogoDevTwoTone sx={{ borderRadius: 1.3, height: 27, width: 27, padding: 0 }} color="inherit" fontSize="large" />
                </div>
            </Alert>
        </Snackbar>
    )
}
