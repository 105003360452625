import axios from "axios";

export async function getWins(raffleId: number) {
  return axios.get(`${process.env.REACT_APP_API_URL}/wins/${raffleId}`);
}
export async function makeRaffle(data: number[]) {
  return axios.post(`${process.env.REACT_APP_API_URL}/wins`, data);
}
export async function cancelWin(
  winId: any,
  isCanceled: boolean,
  canceledReason?: string
) {
  return axios.put(
    `${process.env.REACT_APP_API_URL}/wins/${winId}?isCanceled=${isCanceled}&note=${canceledReason}`
  );
}
