import axios from "axios";
import IAddManuallyForm from "../models/IAddManuallyForm";
import { createSearchParams } from "react-router-dom";

export async function getSignature(data: IAddManuallyForm) {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/signature?${createSearchParams(
      data as any
    )}`
  );
}
