import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useEffect, useState } from "react";
import IStore from "../../models/IStore";
import GenericInputPage from "./GenericInputPage";
import { Button, InputAdornment, MenuItem, Select } from "@mui/material";
import { TextFieldWithIcon, TextFieldWithoutIcon } from "../GenericTextInput";
import { getStores } from "../../api/StoresApi";
import { getSignature } from "../../api/Signature";
import { createSearchParams, useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { IGeneralProps } from "../../models/IGeneralProps";
import { SnackbarType } from "../../enums/SnackbarType";
import IAddManuallyForm from "../../models/IAddManuallyForm";
import axios from "axios";
import { NavigateTo } from "../../enums/NavigateTo";
import UnAuthorizedPage from "./UnAuthorizedPage";
import catchFunction from "../utils/CatchFunction";
const loyaltyOptions = [
  {
    id: 1,
    name: "לא",
    value: "false",
  },
  {
    id: 2,
    name: "כן",
    value: "true",
  },
];
export default function AddManuallyPage(props: IGeneralProps) {
  const navigateTo = useNavigate();

  let didError: boolean = false;

  const [isTotalError, setIsTotalError] = useState<boolean>(false);
  const [isNoteNumberError, setIsNoteNumberError] = useState<boolean>(false);
  const [isPosNumberError, setIsPosNumberError] = useState<boolean>(false);
  const [isDateError, setIsDateError] = useState<boolean>(false);
  const [isStoreError, setIsStoreError] = useState<boolean>(false);
  const [selectedStore, setSelectedStore] = useState<IStore | null>(null);
  const [selectedLoyalty, setSelectedLoyalty] = useState(loyaltyOptions[0]);
  const [isLoyaltyError, setIsLoyaltyError] = useState<boolean>(false);
  const [total, setTotal] = useState<number | string>("");
  const [phone, setPhone] = useState<string | null>("");
  const [date, setDate] = useState<Date | null>(null);
  const [stores, setStores] = useState<IStore[]>([]);
  const [noteNumber, setNoteNumber] = useState<string>("");
  const [posNumber, setPosNumber] = useState<string>("");

  function setDidError(value: boolean) {
    if (didError != value) didError = value;
  }
  function isNumbersOrEmpty(e: any) {
    return !isNaN(e.target.value) || e.target.value == "";
  }

  function setTotalFromInput(event: any) {
    setTotal(event.target.value);
    setDidError(false);
    if (isTotalError) {
      setIsTotalError(false);
    }
  }
  function setDateFromInput(event: any) {
    setDate(new Date(event));
    setDidError(false);
    if (isDateError) {
      setIsDateError(false);
    }
  }
  function setNoteNumberFromInput(event: any) {
    setNoteNumber(event.target.value);
    setDidError(false);
    if (isNoteNumberError) {
      setIsNoteNumberError(false);
    }
  }
  function setPosNumberFromInput(event: any) {
    setPosNumber(event.target.value);
    setDidError(false);
    if (isPosNumberError) {
      setIsPosNumberError(false);
    }
  }
  function setStoreFromInput(event: any) {
    setSelectedStore(event.target.value);
    setDidError(false);
    if (isStoreError) {
      setIsStoreError(false);
    }
  }
  function setLoyaltyFromInput(event: any) {
    setSelectedLoyalty(event.target.value);
    setDidError(false);
    if (isLoyaltyError) {
      setIsLoyaltyError(false);
    }
  }

  function formValidations() {
    if (!selectedStore) {
      setIsStoreError(true);
      setDidError(true);
    }
    if (
      (noteNumber.length != 4 && noteNumber.length != 7) ||
      isNaN(Number(noteNumber))
    ) {
      setIsNoteNumberError(true);
      setDidError(true);
    }
    if (posNumber.length != 2 || isNaN(Number(posNumber))) {
      setIsPosNumberError(true);
      setDidError(true);
    }
    if (!selectedLoyalty.id) {
      setIsLoyaltyError(true);
      setDidError(true);
    }

    if (!total || total == "" || (total as number) < 200) {
      setIsTotalError(true);
      setDidError(true);
    }
    if (moment(date).isAfter(new Date(Date.now())) || date == null) {
      setIsDateError(true);
      setDidError(true);
    }
  }

  function getStoreNumber(storeId?: number): string {
    let storeIdByString = storeId?.toString() || "";
    if (storeIdByString.length == 1) {
      return "00" + storeIdByString;
    }
    if (storeIdByString.length == 2) {
      return "0" + storeIdByString;
    }
    return storeIdByString;
  }
  function clearForum() {
    setIsTotalError(false);
    setIsNoteNumberError(false);
    setIsPosNumberError(false);
    setIsDateError(false);
    setIsStoreError(false);
    setSelectedStore(null);
    setSelectedLoyalty(loyaltyOptions[0]);
    setTotal("");
    setPhone("");
    setDate(null);
    setNoteNumber("");
    setPosNumber("");
  }
  async function sendLinkToCustomer() {
    let data: IAddManuallyForm | undefined = await onSubmitClick(true);
    if (data) {
      props.setSnackbar({
        open: true,
        type: SnackbarType.success,
        message: "הלינק נשלח ללקוח",
      });
    }
  }
  async function continueToRegistration() {
    let data: IAddManuallyForm | undefined = await onSubmitClick();
    if (data) {
      const url = `${window.location.origin}${
        NavigateTo.REGISTRATION
      }?${`${createSearchParams(data as any)}`}`;
      window.open(url, "_blank"); // פותח את הנתיב בחלון חדש

      // navigateTo(
      //   {
      //     pathname: NavigateTo.REGISTRATION,
      //     search: `${createSearchParams(data as any)}`,
      //   }
      // );
      props.setSnackbar({
        open: true,
        type: SnackbarType.success,
        message: "הפרטים נוספו בהצלחה. הינך מועבר להשלמת הנתונים בדף הנחיתה",
      });
    }
  }

  async function onSubmitClick(
    sendLinkToCustomer: boolean = false
  ): Promise<IAddManuallyForm | undefined> {
    formValidations();
    try {
      if (didError) {
        props.setSnackbar({
          open: true,
          type: SnackbarType.error,
          message: "הוזנו נתונים לא חוקיים",
        });
      } else {
        let data: IAddManuallyForm = {
          number:
            (moment(date).format("DDMMYY")?.toString() || "") +
            (getStoreNumber(selectedStore?.id) || "") +
            posNumber +
            noteNumber,
          orderDate: date?.toISOString() || "",
          store: selectedStore?.name || "",
          total: total?.toString() || "",
          nonce: Math.floor(Math.random() * 10000000).toString(),
          sendToPhone: sendLinkToCustomer ? phone : null,
          isLoyalty: selectedLoyalty.value,
        };
        props.setLoading({ open: true });
        let response = await getSignature(data);
        if (!response) return;
        data.signature = response.data;
        data.isManual = "true";
        clearForum();

        return data;
      }
    } catch (response: any) {
      catchFunction({ setSnackbar: props.setSnackbar, error: response });
    } finally {
      props.setLoading({ open: false });
    }
  }

  useEffect(() => {
    if (axios.defaults.headers.common["Authorization"]) {
      getAndSetStores();
    } else {
      setTimeout(() => {
        getAndSetStores();
      }, 2000);
    }
  }, []);
  async function getAndSetStores() {
    getStores()
      .then((response: any) => setStores(response ? response.data : []))
      .catch((error: any) =>
        catchFunction({ setSnackbar: props.setSnackbar, error: error })
      );
  }

  return (
    <>
      {!props.user?.helpDeskPermission ? (
        <UnAuthorizedPage />
      ) : (
        <>
          <GenericInputPage
            title={"הקלדת חשבונית"}
            generalProps={props}
            body={
              <>
                <div style={{ display: "flex", width: "100%", gap: "20px" }}>
                  <div style={{ width: "50%" }}>
                    <TextFieldWithoutIcon
                      fullWidth={true}
                      label="מספר פתקית"
                      variant="outlined"
                      color="info"
                      value={noteNumber}
                      error={isNoteNumberError}
                      required={true}
                      inputProps={{ maxLength: 7 }}
                      onInput={(e: any) => {
                        e.target.value = isNumbersOrEmpty(e)
                          ? e.target.value
                          : noteNumber;
                      }}
                      onChange={(value: any) => setNoteNumberFromInput(value)}
                      helperText="4 / 7 ספרות"
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <TextFieldWithoutIcon
                      fullWidth={true}
                      label="מספר קופה"
                      variant="outlined"
                      color="info"
                      value={posNumber}
                      error={isPosNumberError}
                      required={true}
                      inputProps={{ maxLength: 2 }}
                      onInput={(e: any) => {
                        e.target.value = isNumbersOrEmpty(e)
                          ? e.target.value
                          : posNumber;
                      }}
                      onChange={(value: any) => setPosNumberFromInput(value)}
                      helperText="2 ספרות"
                    />
                  </div>
                </div>
                <div style={{ display: "flex", width: "100%", gap: "20px" }}>
                  <div style={{ width: "50%" }}>
                    <TextFieldWithIcon
                      label="סכום חשבונית"
                      type="number"
                      error={isTotalError}
                      onChange={(value: any) => setTotalFromInput(value)}
                      variant="outlined"
                      color="info"
                      value={total}
                      required={true}
                      startIconComponent={
                        <InputAdornment position="start">₪</InputAdornment>
                      }
                      iconPosition="start"
                      helperText='200 ש"ח ומעלה'
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <TextFieldWithoutIcon
                      fullWidth={true}
                      label="טלפון נייד"
                      variant="outlined"
                      color="info"
                      value={phone}
                      // error={isPosNumberError}
                      // required={true}
                      inputProps={{ maxLength: 10, minLength: 10 }}
                      onInput={(e: any) => {
                        debugger;
                        e.target.value = isNumbersOrEmpty(e)
                          ? e.target.value
                          : phone;
                      }}
                      onChange={(event: any) => setPhone(event.target.value)}
                      helperText="לשליחת המסרון (אופציונלי)"
                    />
                  </div>
                </div>
                <div style={{ display: "flex", width: "100%", gap: "20px" }}>
                  <div style={{ width: "50%" }}>
                    <TextFieldWithIcon
                      variant="outlined"
                      fullWidth={true}
                      color="info"
                      label="סניף"
                      error={isStoreError}
                      value={selectedStore}
                      required={true}
                      select={
                        <Select
                          variant="outlined"
                          color="primary"
                          error={isStoreError}
                          label="סניף"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedStore}
                          sx={{ fontSize: "18px", textAlign: "start" }}
                          onChange={(value: any) => setStoreFromInput(value)}
                        >
                          {stores.map((store, index) => (
                            // @ts-ignore - necessary to load object into value
                            <MenuItem
                              sx={{
                                justifyContent: "end",
                                fontFamily: "Alef Hebrew, sans-serif",
                                fontSize: "18px",
                              }}
                              value={store}
                              key={store.id}
                            >
                              {store.name}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <TextFieldWithIcon
                      variant="outlined"
                      fullWidth={true}
                      color="info"
                      label="חבר מועדון"
                      error={isLoyaltyError}
                      value={selectedLoyalty}
                      required={true}
                      select={
                        <Select
                          variant="outlined"
                          color="primary"
                          error={isLoyaltyError}
                          label="חבר מועדון"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedLoyalty}
                          sx={{ fontSize: "18px", textAlign: "start" }}
                          onChange={(value: any) => setLoyaltyFromInput(value)}
                        >
                          {loyaltyOptions.map((loyalty, index) => (
                            // @ts-ignore - necessary to load object into value
                            <MenuItem
                              sx={{
                                justifyContent: "end",
                                fontFamily: "Alef Hebrew, sans-serif",
                                fontSize: "18px",
                              }}
                              value={loyalty}
                              key={loyalty.id}
                            >
                              {loyalty.name}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                    />
                  </div>
                </div>

                <TextFieldWithIcon
                  label=""
                  variant="outlined"
                  color="info"
                  select={
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        disableFuture
                        slotProps={{
                          textField: {
                            error: isDateError,
                            required: true,
                          },
                        }}
                        ampm={false}
                        format="YYYY-MM-DD-HH:mm"
                        sx={{ direction: "ltr", textAlign: "start" }}
                        label="מועד ביצוע עסקה"
                        value={date}
                        onChange={(value: any) => setDateFromInput(value)}
                      />
                    </LocalizationProvider>
                  }
                />
                <div style={{ display: "flex", gap: "20px" }}>
                  <Button
                    fullWidth={true}
                    variant="contained"
                    disabled={phone?.length !== 10}
                    color="primary"
                    size="large"
                    sx={{ fontSize: "20px", fontWeight: "bold" }}
                    onClick={sendLinkToCustomer}
                  >
                    שלח מסרון ללקוח
                  </Button>
                  <Button
                    fullWidth={true}
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{ fontSize: "20px", fontWeight: "bold" }}
                    onClick={continueToRegistration}
                  >
                    המשך לדף הנחיתה
                  </Button>
                </div>
              </>
            }
          />
        </>
      )}
    </>
  );
}
