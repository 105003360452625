import React, { useState } from 'react'
import GenericInputPage from './GenericInputPage'
import { Button, IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Login, Visibility, VisibilityOff } from '@mui/icons-material'
import { TextFieldWithIcon, TextFieldWithoutIcon } from '../GenericTextInput'
import { IGeneralProps } from '../../models/IGeneralProps'
import { SnackbarType } from '../../enums/SnackbarType'
import axios from 'axios'
import { login } from '../../api/UserApi'
import jwt_decode from "jwt-decode";
import { NavigateTo } from '../../enums/NavigateTo'
import catchFunction from '../utils/CatchFunction'




export default function LoginPage(props: IGeneralProps) {

    const [isError, setIsError] = useState<boolean>(false)
    const [username, setUsername] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const navigateTo = useNavigate()
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    function updateIsError(value: boolean) {
        if (isError != value) setIsError(value)
    }

    function onUsernameChange(event: any) {
        setUsername(event.target.value)
        updateIsError(false)
    }
    function onPasswordChange(event: any) {
        setPassword(event.target.value)
        updateIsError(false)
    }
    function loginValidations() {
        if (!username || !password) {
            throw new Error("יש להזין שם משתמש וסיסמה")
        }
    }

    async function onLoginClick() {
        props.setLoading({ open: true, message: "הנתונים נבדקים..." })
        try {
            loginValidations()
            const serverResponse = await login({ username: username, password: password })
            const token = serverResponse.data.accessToken;
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            let decode: any = jwt_decode(token);
            sessionStorage.setItem('token', token);
            sessionStorage.setItem('user', JSON.stringify(decode.user));
            if (props.setUser) props.setUser(decode.user)
            props.setSnackbar({ open: true, type: SnackbarType.success, message: `ברוך הבא, ${decode.user.userName}` })
            if (decode.user.helpDeskPermission && decode.user.rafflePermission) {
                navigateTo(NavigateTo.DASHBOARD_ROUTER)
            }
            else if (decode.user.rafflePermission) {
                navigateTo(NavigateTo.DASHBOARD)
            }
            else {
                navigateTo(NavigateTo.ADD_MANUALLY)
            }
        }
        catch (error: any) {
            updateIsError(true)
            catchFunction({setSnackbar:props.setSnackbar, error:error});
        }
        finally {
            props.setLoading({ open: false })
        }
    }

    function onKeyDown(event: any) {
        if (event.key === 'Enter') onLoginClick()
    }

    return (
        <GenericInputPage
            title={'כניסת משתמשים'}
            body={
                <>
                    <TextFieldWithoutIcon
                        color='info'
                        variant="outlined"
                        label='שם משתמש'
                        required={true}
                        error={isError}
                        value={username}
                        onChange={((value: any) => onUsernameChange(value))}
                        onKeyDown={(event: any) => onKeyDown(event)}
                    />
                    <TextFieldWithIcon
                        onKeyDown={(event: any) => onKeyDown(event)}
                        color='info'
                        variant="outlined"
                        label="סיסמה"
                        required={true}
                        value={password}
                        onChange={((value: any) => onPasswordChange(value))}
                        showPassword={showPassword}
                        type='password'
                        error={isError}
                        endIconComponent={<IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>}
                        iconPosition='start'
                    />
                    <Button
                        variant='contained'
                        color="primary"
                        size='large'
                        endIcon={<Login fontSize='medium' />}
                        sx={{ fontSize: "22px", fontWeight: "bold", gap: "15px" }}
                        onClick={() => onLoginClick()}
                    >היכנס
                    </Button>
                </>
            } />
    )
}
