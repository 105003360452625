import React from "react";
import "./footerStyle.scss";
const Footer = () => {
  return (
    <div className="footer">
      <p>
        מבצע ההגרלות נערך בהתאם להוראות ההיתר הכללי לעריכת הגרלות לפרסומת מסחרית
        לפי חוק העונשין, התשל"ז 1977- . בכפוף לתקנון. בתוקף בין התאריכים
        1.6-13.7.23 . התמונה להמחשה בלבד, טל"ח. עורכת ההגרלה מ. יוחננוף ובניו
        (1988) בע"מ, ההגרלה בפיקוח עו"ד. לפרטים נוספים ניתן לפנות למחלקת קשרי
        לקוחות בטלפון:
        <a href="tel:053-7002121">053-7002121</a>
        או במייל
        <a href="mailto:Service@yochananof.co.il">Service@yochananof.co.il</a>.
        פרטים נוספים ותקנון המבצע באתר: www.yochananof.co.il , בפייסבוק
        <a href="https://www.facebook.com/yochananof" target="_blank">
          <img
            src={process.env.PUBLIC_URL + "/images/facebook-256.png"}
            alt="Facebook"
          />
          יוחננוף – סופרשוק לכולם,
        </a>
        באינסטגרם
        <a
          href="https://www.instagram.com/yochananof_supershuk"
          target="_blank"
        >
          <img
            src={process.env.PUBLIC_URL + "/images/instagram(1).png"}
            alt="Instagram"
          />
          yochananof_supershuk ,
        </a>
        <a href="https://www.tiktok.com/@yochananof_super" target="_blank">
          ב- yochananof_super tiktok
        </a>
        ובאפליקציית יוחננוף.
      </p>

      <p className="branches">סניפים בפריסה ארצית</p>
    </div>
  );
};

export default Footer;
