import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment-timezone";

import RegistrationForm from "../registrationForm/RegistrationForm";
import {
  postCreateCards,
  postVerifyTransaction,
} from "../../api/registrationApi";
import {
  ICustomer,
  ITransactionRequest,
  ITransactionResponse,
} from "../../models/types";

import "./index.scss";
import Messages, { IMessageObj } from "../msg/Messages";
import { SnackbarType } from "../../../dashboard/enums/SnackbarType";
import { CircularProgress } from "@mui/material";
import Footer from "../footer/Footer";

interface IProps {
  setSnackbar: ({
    open,
    type,
    message,
  }: {
    open: boolean;
    type?: SnackbarType;
    message?: string;
  }) => void;
}

const LandingPage = ({ setSnackbar }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [details, setDetails] = useState<ITransactionRequest>();
  const [initialResponse, setInitialResponse] =
    useState<ITransactionResponse>();
  const [errorResponse, setErrorResponse] = useState<string | undefined>(
    undefined
  );
  const [response, setResponse] = useState<ITransactionResponse>();
  const [messageObj, setMessageObj] = useState<IMessageObj>();
  const nav = useNavigate();

  useEffect(() => {
    setDetails({
      manual: {
        number: searchParams.get("number") ?? "",
        total: searchParams.get("total") ?? "",
        orderDate: searchParams.get("orderDate") ?? "",
        store: searchParams.get("store") ?? "",
        signature: searchParams.get("signature") ?? "",
        nonce: searchParams.get("nonce") ?? "",
        isManual: searchParams.get("isManual") ?? "false",
        isLoyalty: searchParams.get("isLoyalty") ?? "false",
      },
      wizmo: searchParams.get("id") ?? null,
    });
  }, []);

  useEffect(() => {
    // nav(`/registration`);

    if (details && !initialResponse) {
      if (details.manual?.number === "" && details?.wizmo === null) {
        setMessageObj({
          heading: "לא ניתן לטעון את הדף",
        });
      } else {
        setLoading(true);
        postVerifyTransaction(details)
          ?.then((resp) => {
            setTimeout(() => setLoading(false), 400);
            setInitialResponse(resp.data);
            if (resp.data.cards)
              setMessageObj({
                heading: "הרשמה להגרלה כבר קיימת במערכת",
              });
          })
          .catch((error) => {
            setTimeout(() => setLoading(false), 400);
            // setErrorResponse(error.response.data.message);
            setMessageObj({
              heading: error.response.data.message,
            });
          });
      }
    }
  }, [details]);

  useEffect(() => {
    if (errorResponse)
      setSnackbar({
        open: true,
        message: errorResponse,
        type: SnackbarType.error,
      });
  }, [errorResponse]);

  const onSubmit = (data: ICustomer) => {
    setLoading(true);
    setErrorResponse(undefined);
    let obj = {
      manual: details?.manual,
      wizmo: details?.wizmo,
      customer: data,
    };
    postCreateCards(obj)
      ?.then((resp) => {
        setTimeout(() => setLoading(false), 400);
        setResponse(resp.data);
        setMessageObj({
          heading: "קוד/ים מזכים",
          subTitle: `להגרלה שתתקיים בתאריך ${moment(resp.data.raffle.raffleDate)
            .tz("Asia/Jerusalem")
            .format("DD-MM-YYYY")} :`,
          body: "אל דאגה, הקודים גם אצלך בנייד ובמייל",
          cards: resp.data.cards,
          ending: "בהצלחה!",
        });
      })
      .catch((error) => {
        setTimeout(() => setLoading(false), 400);
        setErrorResponse(error.response.data.message);
      });
  };

  return (
    <div className="landing_page">
      <div className="banner">
        <img
          src={process.env.PUBLIC_URL + "/images/410091_Summer_WinACar_LP.jpg"}
          alt="Banner summer raffel"
        />
      </div>
      <div className="main">
        <img
          className="logo"
          src={process.env.PUBLIC_URL + "/images/Y-logo.png"}
          alt="Yochananof-Logo"
        />
        {loading && (
          <div className="loading">
            <CircularProgress size={"5rem"} color="inherit" />
            <p>נתונים בבדיקה</p>
          </div>
        )}
        {initialResponse && !loading && !messageObj && (
          <div className="form">
            <RegistrationForm details={initialResponse} onSubmit={onSubmit} />
          </div>
        )}
        {messageObj && !loading && (
          <div className="msgArea">
            <Messages
              body={messageObj?.body}
              heading={messageObj?.heading}
              cards={messageObj?.cards}
              ending={messageObj?.ending}
              subTitle={messageObj?.subTitle}
            />
          </div>
        )}
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
