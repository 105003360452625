import { Button, IconButton } from '@mui/material'
import { title } from 'process'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import GenericInputPage from './GenericInputPage'
import { TextFieldWithIcon, TextFieldWithoutIcon } from '../GenericTextInput'
import { AddToQueue, DisplaySettings, NoteAdd } from '@mui/icons-material'
import { IGeneralProps } from '../../models/IGeneralProps'
import UnAuthorizedPage from './UnAuthorizedPage'
import { UserType } from '../../enums/UserType'
import Navbar from '../navbar/Navbar'
import { NavigateTo } from '../../enums/NavigateTo'
import IUser from '../../models/IUser'

export default function DashboardRouerPage(props: IGeneralProps) {    

    const navigateTo = useNavigate()

    return (
        <>
            {(!props.user?.rafflePermission  || !props.user.rafflePermission)
                ? <UnAuthorizedPage />
                :
                <>
                    <GenericInputPage
                        title=''
                        generalProps={props}
                        body={
                            <>
                                <Button
                                    style={{ display: "flex", justifyContent: "center", fontSize: "30px", fontWeight: "bold", gap: "10px", textAlign: "center", alignItems: "center" }}
                                    variant='contained'
                                    size='large'
                                    color='primary'
                                    endIcon={
                                        <IconButton size='large' disabled >
                                            <DisplaySettings fontSize='large' color='secondary' sx={{ color: "white" }} />
                                        </IconButton>}
                                    onClick={() => navigateTo(NavigateTo.DASHBOARD)}
                                >מסך ניהול הגרלות קיץ</Button>
                                <Button
                                    style={{ display: "flex", justifyContent: "center", fontSize: "30px", fontWeight: "bold", gap: "10px", textAlign: "center", alignItems: "center" }}
                                    variant='contained'
                                    size='large'
                                    color='primary'
                                    endIcon={
                                        <IconButton size='large' disabled >
                                            <NoteAdd fontSize='large' color='secondary' sx={{ color: "white" }} />
                                        </IconButton>}
                                    onClick={() => navigateTo(NavigateTo.ADD_MANUALLY)}
                                >הקלדת חשבונית</Button>
                            </>
                        } />
                </>}
        </>
    )
}
