import { AccountCircle, Info, Logout } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import React from 'react'
import IRaffle from '../../models/IRaffle';
import { getDateWithoutTime } from '../../logic/ToDateFormat';

export function MoreInfoAboutRaffle({ raffle }: { raffle: IRaffle | null }) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
        React.useState<null | HTMLElement>(null);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const isMenuOpen = Boolean(anchorEl);

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            sx={{ fontFamily: "Alef Hebrew, sans-serif" }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
          <MenuItem dir='rtl' sx={{ fontSize: "18px", gap: "10px", textAlign: "center", fontFamily: "Alef Hebrew, sans-serif" }}>
                <Typography
                    justifyContent={"center"}
                    alignItems={"center"}
                    align="center"
                    variant="body1"
                    color="info.dark"
                    sx={{ direction: "rtl", fontFamily: "Alef Hebrew, sans-serif", width:"100%"}}
                >
                    <b>תאריכי צבירת הגרלות:</b>
                    <div>
                        {`${getDateWithoutTime(raffle?.raffle?.startDate)} - ${getDateWithoutTime(raffle?.raffle?.endDate)}`}
                    </div>
                </Typography>
            </MenuItem>
            <MenuItem dir='rtl' sx={{ fontSize: "18px", gap: "10px", textAlign: "center", fontFamily: "Alef Hebrew, sans-serif" }}>
                <Typography
                    justifyContent={"center"}
                    alignItems={"center"}
                    align="center"
                    variant="body1"
                    color="info.dark"
                    sx={{ direction: "rtl", fontFamily: "Alef Hebrew, sans-serif", width:"100%" }}
                >
                    <b>נתוני הגרלות:</b>
                    <div>
                        {`${raffle?.data?.customerQuantity} לקוחות, ${raffle?.data?.transactionQuantity} עסקאות, ${raffle?.data?.cardsQuantity} כרטיסים`}
                    </div>
                </Typography>
            </MenuItem>
        </Menu >
    );

    return (
        <>
            <Tooltip title={<span style={{ direction: "rtl", fontFamily: "Alef Hebrew, sans-serif" }} dir="rtl">מידע נוסף אודות הגרלה</span>}>
                <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                >
                    <Info color="info" />
                </IconButton>
            </Tooltip>
            {renderMenu}
        </>
    )
}
