import React from 'react'
import GenericInputPage from './GenericInputPage'
import { Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { NavigateTo } from '../../enums/NavigateTo'

export default function UnAuthorizedPage() {

    const navigateTo = useNavigate()


    return (
        <GenericInputPage
            title='אופסס...'
            body={
                <>
                    <Typography
                        sx={{ fontSize: '30px', fontFamily: "Alef Hebrew, sans-serif" }}
                        id="tableTitle"
                        component="div"
                        color="info.dark"
                    >
                        נראה שאינך מורשה לגשת לכאן
                    </Typography>
                    <Button
                        variant='contained'
                        color='primary'
                        sx={{ fontSize: "25px" }}
                        onClick={() => navigateTo(NavigateTo.Login)}
                    >
                        עבור לדף הכניסה
                    </Button>
                </>
            }

        />
    )
}
