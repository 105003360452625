import GenericModal from "./modals/GenericModal";
import { IDetailedWin } from "../models/IWinForExcel";

interface IProps {
  isOpen: boolean;
  onClose: any;
  info: IDetailedWin | null;
}

export default function MoreInfoModal(props: IProps) {
  return (
    <GenericModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      body={
        <>
          <div
            style={{
              fontSize: "28px",
              textAlign: "center",
              fontWeight: "bolder",
              fontFamily: "Alef Hebrew, sans-serif",
            }}
          >
            מידע נוסף
          </div>
          <div
            style={{
              fontSize: "20px",
              textAlign: "right",
              fontWeight: "normal",
              gap: "30px",
            }}
          >
            <b>הפרס: </b> {props.info?.prizeName}
            <br />
            <b>מועד הגרלה: </b> {props.info?.raffleDate}
            <br />
            {props.info?.rafflingUsername && (
              <>
                <b>מבצע ההגרלה: </b> {props.info?.rafflingUsername}
                <br />
              </>
            )}
            <b>
              סטטוס:{" "}
              <span
                style={{
                  color: props.info?.isCanceled
                    ? "red"
                    : props.info?.cardNumber
                    ? "green"
                    : "",
                }}
              >
                {!props.info?.cardNumber
                  ? "לא הוגרל"
                  : props.info?.isCanceled
                  ? "בוטל"
                  : "פעיל"}
              </span>{" "}
            </b>
            <hr />
            {props.info?.cardNumber && (
              <div>
                <b>שם לקוח: </b> {props.info?.customerName}
                <br />
                <b>מספר זהות: </b> {props.info?.customerIdNumber}
                <br />
                <b>מספר טלפון: </b> {props.info?.customerMobilePhone}
                <br />
                <b>כתובת אימייל: </b> {props.info?.customerEmail}
                <br />
                <b>כתובת: </b>{" "}
                {`${props.info?.customerCity}, רחוב ${props.info?.customerStreet}`}
                <br />
                <hr />
                <b>סוג עסקה: </b>{" "}
                {props.info?.isManual
                  ? "נוסף ידנית"
                  : props.info?.transactionType == "in_store"
                  ? "חנות"
                  : props.info?.transactionType == "online"
                  ? "אתר הסחר"
                  : ""}
                <br />
                {props.info?.isManual && (
                  <>
                    <b>מנפיק ידני: </b> {props.info?.manualCreatorUsername}
                    <br />
                  </>
                )}
                {props.info?.store && (
                  <>
                    <b>חנות רכישה / איסוף: </b> {props.info?.store}
                    <br />
                  </>
                )}
                <b>מספר הזמנה / חשבונית: </b>{" "}
                {props.info?.transactionNumber.toString()}
                <br />
                <b>סכום הקנייה: </b> {props.info?.total.toString()}
                <br />
                <b>מועד החשבונית: </b> {props.info?.orderDate}
                <hr />
                <b>מועד הרשמה להגרלה: </b> {props.info?.registrationDate}
                <br />
                <b>מועד הזכיה בפועל: </b> {props.info?.created}
                <br />
                <b>קוד מזכה: </b> {props.info?.cardNumber?.toString()}
                <br />
                <b>זכיית מועדון: </b> {props.info?.isLoyalty ? "כן" : "לא"}
                <br />
                {props.info.isCanceled && (
                  <>
                    <hr />
                    <b>מועד ביטול: </b> {props.info?.canceledDate}
                    <br />
                    <b>סיבת ביטול: </b> {props.info?.canceledReason}
                    <br />
                    <b>מבצע ביטול: </b> {props.info?.cancelingUsername}
                    <br />
                  </>
                )}
              </div>
            )}
          </div>
        </>
      }
    />
  );
}
