import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Button, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { Add, ArrowBack, Logout } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import YochananofLogoComponent from '../utils/YochananofLogoComponent';
import { IGeneralProps } from '../../models/IGeneralProps';
import { SnackbarType } from '../../enums/SnackbarType';
import { NavigateTo } from '../../enums/NavigateTo';
import axios from 'axios';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

interface INavbarProps {
  searchText: string;
  setSearchText: any;
  generalProps: IGeneralProps;
}

export default function PrimarySearchAppBar(props: INavbarProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const navigateTo = useNavigate()

  const isMenuOpen = Boolean(anchorEl);

  function logout() {
    props.generalProps.setLoading({ open: true, message: "מבצע התנתקות..." })
      if (props.generalProps.setUser) {
        props.generalProps.setUser(null)
      }
      navigateTo(NavigateTo.Login)
      sessionStorage.setItem('token', "");
      sessionStorage.setItem('user', "");
      delete axios.defaults.headers.common["Authorization"];
      props.generalProps.setLoading({ open: false })
      props.generalProps.setSnackbar({ open: true, type: SnackbarType.info, message: "בוצעה התנתקות" })
  }



  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  function onLogoutClick() {
    logout();
    setAnchorEl(null);

  }



  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      sx={{ fontFamily: "Alef Hebrew, sans-serif" }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem disabled={true} dir='rtl' sx={{ fontSize: "18px", fontFamily: "Alef Hebrew, sans-serif" }}>{`שלום, ${props.generalProps.user?.userName || ""}`} </MenuItem>
      <MenuItem onClick={onLogoutClick} dir='rtl' sx={{ gap: "10px", textAlign: "center", fontFamily: "Alef Hebrew, sans-serif" }}>
        <Logout fontSize="small" />
        <Typography sx={{ textAlign: "center", fontSize: "18px", fontFamily: "Alef Hebrew, sans-serif" }} variant="body2" color="text.secondary">
          התנתק
        </Typography>


      </MenuItem>
    </Menu>
  );


  return (
    <Box sx={{ flexGrow: 1, width: "100%" }}>
      <AppBar position="sticky">
        <Toolbar>
          <div style={{ height: "100%", alignItems: "center", paddingLeft: "10px" }}>
            <YochananofLogoComponent style={{ height: "40px", paddingTop: "7px" }} />
          </div>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ fontSize: "30px", fontWeight: "bold", padding: "8px", display: { xs: 'none', sm: 'block' } }}
          >
            הגרלות קיץ

          </Typography>
          {window.location.pathname == "/dashboard" && <Search style={{ display: "flex", alignItems: "center" }}>
            <StyledInputBase
              placeholder="חפש..."
              value={props.searchText}
              inputProps={{ 'aria-label': 'search' }}
              onChange={((value: any) => props.setSearchText(value.target.value))} />
            <SearchIconWrapper style={{ position: "initial" }}  >
              <SearchIcon />
            </SearchIconWrapper>
          </Search>}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
          <Tooltip title={<span style={{ direction: "rtl", fontFamily: "Alef Hebrew, sans-serif" }} dir="rtl">חשבון והתנתקות</span>}>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle fontSize='large' />
            </IconButton>
            </Tooltip>
              <Tooltip title={<span style={{ direction: "rtl", fontFamily: "Alef Hebrew, sans-serif" }} dir="rtl">חזרה לתפריט הראשי</span>}>
                <IconButton color='inherit' >
                  <Box
                    sx={{ alignItems: "center", textAlign: "center", display: "flex", justifyContent: "center" }}>
                    <ArrowBack fontSize='small' color='inherit' onClick={(() => navigateTo(-1))} />
                  </Box>
                </IconButton>
              </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </Box>
  );
}