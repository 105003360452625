import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@mui/material";

interface IProps {
  open: boolean;
  title?: string;
  terms?: JSX.Element;
  setIsAgreeTerms?: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
}

const ScrollingDialog = ({
  open,
  title,
  onClose,
  terms,
  setIsAgreeTerms,
}: IProps) => {
  const [scroll, setScroll] = useState<DialogProps["scroll"]>("paper");
  const [isBottom, setIsBottom] = useState<boolean>(false);
  const [contentHeight, setContentHeight] = useState<number | null>(null);
  const dialogRef = useRef<HTMLDivElement>(null);
  const descriptionElementRef = React.useRef<HTMLElement>(null);

  // const handleScroll = () => {
  //   if (
  //     dialogRef.current &&
  //     contentHeight &&
  //     dialogRef.current.scrollTop + dialogRef.current.clientHeight >=
  //       contentHeight
  //   ) {
  //     setIsBottom(true);
  //   } else {
  //     setIsBottom(false);
  //   }
  // };
  // useEffect(() => {
  //   setIsBottom(false);
  //   if (dialogRef.current) {
  //     setContentHeight(dialogRef.current.scrollHeight);
  //   }
  // }, [terms, dialogRef?.current?.scrollHeight]);

  // const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
  //   setScroll(scrollType);
  // };

  const handleClose = () => {
    setScroll("paper");
    onClose(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={false}
      maxWidth={"md"}
      scroll="paper"
      sx={{ padding: "12px" }}
    >
      {/* <DialogTitle>{title}</DialogTitle> */}
      <DialogContent
        dividers={scroll === "paper"}
        ref={dialogRef}
        // onScroll={handleScroll}
        sx={{ paddingX: "20px" }}
      >
        <DialogContentText>{terms && terms}</DialogContentText>
        <DialogActions sx={{ justifyContent: "center", margin: "12px" }}>
          <Button
            onClick={() => {
              if (terms && setIsAgreeTerms) {
                setIsAgreeTerms(false);
              }
              handleClose();
            }}
          >
            ביטול
          </Button>
          <Button
            onClick={() => {
              if (terms && setIsAgreeTerms) {
                setIsAgreeTerms(true);
              }
              handleClose();
            }}
            // disabled={!isBottom}
            variant="contained"
            color="primary"
          >
            אישור
          </Button>
        </DialogActions>
      </DialogContent>
      <p style={{ textAlign: "center", color: "rgb(215, 11, 11)" }}>
        יש לגלול את העמוד לקריאת התקנון ואישור תנאי ההשתתפות
      </p>
    </Dialog>
  );
};

export default ScrollingDialog;
