import { CircularProgress, Modal } from "@mui/material";
import { useEffect, useRef } from "react";
import { SnackbarType } from "../../../dashboard/enums/SnackbarType";

interface IProps {
  loadingState?: { open: boolean; message?: string };
  setLoading: ({ open, message }: { open?: boolean; message?: string }) => void;
  setSnackbar: ({
    open,
    type,
    message,
  }: {
    open: boolean;
    type?: SnackbarType;
    message?: string;
  }) => void;
}

function LoadingBody(props: IProps) {

  return (
    <div
      style={{
        border: "0px",
        display: "block",
        direction: "rtl",
        fontSize: "40px",
        color: "white",
        fontWeight: "bold",
      }}
    >
      {props.loadingState?.message}
      <div
        style={{
          border: "0px",
          display: "flex",
          justifyContent: "center",
          margin: "20px",
        }}
      >
        <CircularProgress size={130} color="inherit" />
      </div>
    </div>
  );
}

export function PartialLoading(props: IProps) {
  return (
    <div
      style={{
        border: "0px",
        display: "flex",
        height: "100%",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <LoadingBody
        loadingState={props.loadingState}
        setLoading={props.setLoading}
        setSnackbar={props.setSnackbar}
      />
    </div>
  );
}
export function FullLoading(props: IProps) {
  return (
    <Modal
      sx={{
        border: "0px",
        display: "flex",
        height: "100%",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={props.loadingState?.open || false}
    >
      <LoadingBody
        loadingState={props.loadingState}
        setLoading={props.setLoading}
        setSnackbar={props.setSnackbar}
      />
    </Modal>
  );
}
