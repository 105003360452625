import moment from "moment-timezone";
import { IWin } from "../models/IWin";
import { IDetailedWin } from "../models/IWinForExcel";
import { getDateAndTime } from "./ToDateFormat";

export function convertWinsForExcel(oldWins: IWin[]): IDetailedWin[] {
  let newArray: IDetailedWin[] = [];
  oldWins.map((oldRow) => {
    let newRow: IDetailedWin = {
      isSelected: oldRow.isSelected || false,
      canceledDate: getDateAndTime(oldRow.canceledDate),
      canceledReason: oldRow.canceledReason,
      cancelingUsername: oldRow.cancelingUser?.userName,
      manualCreatorUsername: oldRow.card?.transaction?.manualCreator?.userName,
      cardNumber: oldRow.card?.number,
      isLoyalty: oldRow.card?.isLoyalty,
      customerCity: oldRow.card?.transaction?.customer?.city,
      customerEmail: oldRow.card?.transaction?.customer?.email,
      created: getDateAndTime(oldRow.created),
      customerName: oldRow.card?.transaction?.customer?.firstName
        ? oldRow.card?.transaction?.customer?.firstName +
          " " +
          oldRow.card?.transaction?.customer?.lastName
        : "",
      customerIdNumber: oldRow.card?.transaction?.customer?.misparZehut,
      customerStreet: oldRow.card?.transaction?.customer?.street,
      customerMobilePhone: oldRow.card?.transaction?.customer?.mobilePhone,
      isCanceled: oldRow.isCanceled,
      isManual: oldRow.card?.transaction?.isManual,
      isCustomerAllowMailing: oldRow.card?.transaction?.customer?.allowMailing,
      isOneTimeWin: oldRow.rafflePrize?.prize?.isOneTimeWin,
      raffleDate: getDateAndTime(oldRow.rafflePrize?.raffle?.raffleDate),
      winId: oldRow.id,
      orderDate: getDateAndTime(oldRow.card?.transaction?.registrationDate),
      raffleEndDate: getDateAndTime(oldRow.rafflePrize?.raffle?.endDate),
      raffleId: oldRow.rafflePrize?.raffle?.id,
      rafflePrizeId: oldRow.rafflePrize?.id,
      prizeName: oldRow.rafflePrize?.prize?.name,
      raffleStartDate: getDateAndTime(oldRow.rafflePrize?.raffle?.startDate),
      rafflingUsername: oldRow.rafflingUser?.userName,
      registrationDate: getDateAndTime(
        oldRow.card?.transaction?.registrationDate
      ),
      store: oldRow.card?.transaction?.store,
      total: oldRow.card?.transaction?.total,
      transactionNumber: oldRow.card?.transaction?.number,
      transactionType: oldRow.card?.transaction?.type,
    };
    newArray.push(newRow);
  });
  return newArray;
}
