import React from "react";
import "./msgStyle.scss";
import { ICard } from "../../models/types";

export interface IMessageObj {
  cards?: ICard[];
  heading?: string;
  body?: string;
  ending?: string;
  subTitle?: string;
}

const Messages = ({ cards, heading, body, ending, subTitle }: IMessageObj) => {
  return (
    <div id="msg">
      <h2>{heading}</h2>
      <h2 style={{ marginBlockStart: "0" }}>{subTitle}</h2>
      {cards && cards.map((card) => <p key={card.id}>{card.number}</p>)}
      <p style={{ marginBlockStart: "2rem" }}>{body}</p>
      <h3>{ending}</h3>
    </div>
  );
};

export default Messages;
