import GenericModal from './GenericModal'
import { Button } from '@mui/material';

interface IProps {
    isOpen: boolean;
    amountInThisRaffle: number;
    onYes: any;
    onNo: any;
}

export default function AreYouSureToMakeRaffle(props: IProps) {


    return (
        <GenericModal
            isOpen={props.isOpen}
            onClose={props.onNo}
            body={<div>
                <div style={{ fontSize: "25px" }}>{props.amountInThisRaffle > 1 ? `האם אתה בטוח שברצונך להגריל את ${props.amountInThisRaffle} הפרסים שנבחרו?` : "האם אתה בטוח שברצונך להגריל את הפרס שנבחר?"}
                </div>
                <div style={{ display: "flex", justifyContent: "center", gap: "25px", marginTop: "20px" }}>
                    <Button
                        sx={{ fontFamily: "Alef Hebrew, sans-serif", fontWeight: "bold", fontSize: "20px" }}
                        variant='contained'
                        color='primary'
                        onClick={props.onYes}
                        size='large'>כן, בצע הגרלה!</Button>
                    <Button
                        sx={{ fontFamily: "Alef Hebrew, sans-serif", fontWeight: "bold", fontSize: "20px" }}
                        variant='contained'
                        color='inherit'
                        onClick={props.onNo}
                        size='large'>לא, אל תגריל</Button>
                </div>
            </div>
            }
        />

    )
}
