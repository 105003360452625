import axios from "axios";

interface IProps{
  username:string;
  password:string
}

export async function login(data: IProps) {
  return await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`,  data );
}

export async function logOut(username: string) {
  return await axios.put(`${process.env.REACT_APP_API_URL}/logout`, {
    username,
  });
}
