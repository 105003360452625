import { Cancel } from '@mui/icons-material';
import { Box,  IconButton, Modal } from '@mui/material'


interface IProps {
    isOpen: boolean;
    onClose?: any;
    body: JSX.Element
}
export default function GenericModal(props: IProps) {


    return (
        <Modal
            style={{ rowGap: "0px", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", direction: "rtl" }}
            open={props.isOpen}
            onClose={props.onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                gap={"0px"}
                sx={{
                    fontSize: 40,
                    gap: "0px",
                    display: "grid",
                    fontWeight: "bold",
                    padding: 5,
                    paddingRight: 1,
                    paddingTop: 0,
                    backgroundColor: "#F0F8FF",
                    // backgroundColor: 'info.light',
                    border: 2,
                    color: "primary.dark",
                    borderColor: "primary.main",
                    borderRadius: 6,
                    '&:hover': {
                        // backgroundColor: 'primary.main',
                        transition: "0.8s",
                        opacity: [0.1, 0.1, 0.9],
                    },
                }}>
                <div>
                    <IconButton>
                        <Cancel color='primary' onClick={props.onClose} />
                    </IconButton>
                </div>
                <Box sx={{ paddingRight: 4, gap: 12 }}>
                    {props.body}
                </Box>
            </Box>
        </Modal>
    )
}
