import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import "./formStyle.scss";
import { ICustomer, ITransactionResponse } from "../../models/types";
import ScrollDialog from "../dialog/ScrollDialog";
import TermsAndConditions from "../terms/TermsAndConditions";
import { getApiData } from "../../api/dataGov";
import AlertDialog from "../dialog/AlertDialog";

type FormData = {
  firstName: string;
  lastName: string;
  id: string;
  phone_prefix: string;
  mobilePhone: string;
  email: string;
  street: string;
  city: string;
  isEighteen: boolean;
  isAgreeAds: boolean;
  isAgreeTerms: boolean;
};
interface ISearchString {
  resource_id: string;
  limit: number;
  offset: number;
  distinct: boolean;
  include_total: boolean;
  sort: string;
  fields: string;
  plain: boolean;
  q: IQ;
}
interface IQ {
  שם_ישוב?: string;
  שם_רחוב?: string;
}
interface IProps {
  details?: ITransactionResponse;
  onSubmit: (data: ICustomer) => void;
}
const RegistrationForm = ({ details, onSubmit }: IProps) => {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState<string>();
  const [time, setTime] = useState<string>();
  const [isAgreeTerms, setIsAgreeTerms] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<ISearchString>({
    resource_id: "9ad3862c-8391-4b2f-84a4-2d4c68625f4b",
    limit: 10000,
    offset: 0,
    distinct: true,
    include_total: false,
    sort: "",
    fields: "",
    plain: false,
    q: { שם_ישוב: "" },
  });
  const [stateCities, setStateCities] = useState([]);
  const [cities, setCities] = useState([{ שם_ישוב: "" }]);
  const [cityStreets, setCityStreets] = useState([]);
  const [streets, setStreets] = useState([{ שם_רחוב: "" }]);
  const [idError, setIdError] = useState<boolean>(false);
  const [cityError, setCityError] = useState<boolean>(false);
  const [isLoadingStreets, setIsLoadingStreets] = useState<boolean>(false);
  const [isOpenAlertDialog, setIsOpenAlertDialog] = useState<boolean>(false);
  const cityRef = useRef<any>(null);
  const streetRef = useRef<any>(null);
  useEffect(() => {
    if (details?.orderDate) {
      let date = details?.orderDate;
      setDate(
        new Date(date)
          .toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
          .replace(/\//g, ".")
      );

      setTime(
        new Date(date).getHours().toString().padStart(2, "0") +
          ":" +
          new Date(date).getMinutes().toString().padStart(2, "0")
        // +
        // ":" +
        // new Date(date).getSeconds().toString().padStart(2, "0")
      );
      getApiData(
        `?resource_id=${searchParams.resource_id}&limit=${searchParams.limit}&offset=${searchParams.offset}&include_total=${searchParams.include_total}&fields="שם_ישוב"&sort="שם_ישוב"&distinct=${searchParams.distinct}&plain=${searchParams.plain}`
      )
        .then((resp) => {
          resp.data.result.records.forEach((element: any) => {
            element.שם_ישוב = element.שם_ישוב.trim();
          });
          setStateCities(resp.data.result.records);
        })
        .catch((error) => console.log(error));
    }
  }, [details]);

  useEffect(() => {
    document.getElementById("firstName")?.focus();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<FormData>({});

  useEffect(() => {
    errors?.firstName && document.getElementById("firstName")?.focus();
    errors?.lastName && document.getElementById("lastName")?.focus();
    errors?.id && document.getElementById("id")?.focus();
    errors?.mobilePhone && document.getElementById("phone")?.focus();
    errors?.email && document.getElementById("email")?.focus();
    errors?.city && document.getElementById("city")?.focus();
    errors?.street && document.getElementById("street")?.focus();
  }, [errors]);

  const isIsraeliIdNumber = (id: string) => {
    if (id.length !== 9) return false;
    return (
      Array.from(id, Number).reduce((counter, digit, i) => {
        return (
          counter +
          (digit * ((i % 2) + 1) > 9
            ? digit * ((i % 2) + 1) - 9
            : digit * ((i % 2) + 1))
        );
      }) %
        10 ===
      0
    );
  };

  const onSub = (formData: FormData) => {
    let customer: ICustomer = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      misparZehut: formData.id,
      mobilePhone: formData.phone_prefix + formData.mobilePhone,
      email: formData.email,
      street: streetRef.current.value,
      city: cityRef.current.value,
      allowMailing: formData.isAgreeAds,
    };
    onSubmit(customer);
  };
  const onEndClick = () => {
    setIsOpenAlertDialog(true);
  };
  const isValidCity = (inputValue: string) => {
    if (
      cities.find((city) => city.שם_ישוב === inputValue && inputValue !== "")
    ) {
      setCityError(false);
      return true;
    } else {
      setCityError(true);
      return false;
    }
  };
  const isValidStreet = async (inputValue: string) => {
    if (cityStreets.length === 0) {
      try {
        await handleSearchCity(cityRef.current.value);
        await handleSelectCity(cityRef.current.value);
      } catch (err) {
        setCityError(true);
      }
    }
    if (
      streets.find(
        (street) => street.שם_רחוב === inputValue && inputValue !== ""
      )
    ) {
      return true;
    }
    return false;
  };
  const handleSearchCity = (data: string | undefined) => {
    setCityError(false);
    setStreets([{ שם_רחוב: "" }]);
    setCityStreets([]);
    setCities(stateCities.filter((city: any) => city.שם_ישוב.startsWith(data)));
  };

  const handleSelectCity = (data: string | undefined) => {
    setIsLoadingStreets(true);
    if (isValidCity(cityRef.current.value)) {
      getApiData(
        `?resource_id=${searchParams.resource_id}&limit=${
          searchParams.limit
        }&offset=${searchParams.offset}&include_total=${
          searchParams.include_total
        }&fields="שם_רחוב"&sort="שם_רחוב"&distinct=${
          searchParams.distinct
        }&plain=${searchParams.plain}&filters={"שם_ישוב":"${
          cityRef.current.value + " "
        }"}`
      )
        .then((resp) => {
          setCityStreets(resp.data.result.records);
        })
        .catch((error) => console.log(error));
    }
    setIsLoadingStreets(false);
  };
  const handleSearchStreet = async (data: string | undefined) => {
    if (cityStreets.length === 0) {
      try {
        await handleSearchCity(cityRef.current.value);
        await handleSelectCity(cityRef.current.value);
      } catch (err) {
        setCityError(true);
      }
    }
    if (isValidCity(cityRef.current.value)) {
      setStreets(
        cityStreets.filter(
          (street: any) =>
            street.שם_רחוב.startsWith(data) |
            street.שם_רחוב.includes(` ${data}`)
        )
      );
    } else {
      setCityError(true);
    }
  };
  useEffect(() => {
    reset({ isAgreeTerms: isAgreeTerms });
  }, [isAgreeTerms]);
  return (
    <form
      id="registrationForm"
      className="registrationForm"
      onSubmit={handleSubmit(onSub)}
      autoComplete="off"
    >
      <h2>להרשמה להגרלה: </h2>
      <p className="halfWidth">
        <label>שם פרטי:</label>
        <input
          id="firstName"
          type="text"
          // placeholder="שם פרטי"
          {...register("firstName", { required: true, minLength: 2 })}
        />
        <span>{errors?.firstName && "יש להזין שם פרטי"}</span>
      </p>
      <p className="halfWidth">
        <label>שם משפחה:</label>
        <input
          id="lastName"
          type="text"
          // placeholder="שם משפחה"
          {...register("lastName", { required: true, minLength: 2 })}
        />
        <span> {errors?.lastName && "יש להזין שם משפחה"}</span>
      </p>
      <p className="halfWidth">
        <label>ת.ז לצורך אימות:</label>
        <input
          id="id"
          type="number"
          // placeholder="תעודת זהות"
          {...register("id", {
            required: true,
            minLength: 9,
            maxLength: 9,
            validate: (value) => isIsraeliIdNumber(value),
          })}
        />
        <span> {(errors?.id || idError) && "יש להזין מספר זהות תקין"}</span>
      </p>

      <p className="halfWidth">
        <label>טלפון:</label>
        <div className="phone_control">
          <select
            id="phonePrefix"
            {...register("phone_prefix", { required: true })}
          >
            <option value="050">050</option>
            <option value="051">051</option>
            <option value="052">052</option>
            <option value="053">053</option>
            <option value="054">054</option>
            <option value="055">055</option>
            <option value="057">057</option>
            <option value="058">058</option>
          </select>

          <input
            id="phone"
            type="number"
            // placeholder="מס’ טלפון נייד"
            {...register("mobilePhone", {
              required: true,
              pattern: /^[0-9]{7}$/,
            })}
          />
        </div>
        <span> {errors?.mobilePhone && "יש להזין מס' טלפון תקין"}</span>
      </p>
      <p className="fullWidth">
        <label> דואר אלקטרוני:</label>
        <input
          id="email"
          type="email"
          // placeholder="דואר אלקטרוני"
          {...register("email", {
            required: true,
            pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
          })}
        />
        <span> {errors?.email && "יש להזין מייל תקין"}</span>
      </p>

      <p
        onBlur={() => handleSelectCity(cityRef.current.value)}
        className="halfWidth"
        style={{ position: "relative" }}
      >
        <label> עיר:</label>
        <input
          id="city"
          type="text"
          list="cities"
          // placeholder="עיר"
          {...register("city", {
            validate: (value) => isValidCity(cityRef.current.value),
          })}
          ref={cityRef}
          onInput={() => handleSearchCity(cityRef.current.value)}
        />

        <datalist id="cities">
          {cities !== undefined &&
            cities.map((city: any, i) => (
              <option value={city.שם_ישוב} key={city.שם_ישוב}>
                {city.שם_ישוב}
              </option>
            ))}
        </datalist>
        <span>{cityError && "יש לבחור עיר מתוך הרשימה"}</span>
      </p>

      <p className="halfWidth">
        <label> רחוב:</label>
        <input
          id="street"
          type="text"
          list="streets"
          // placeholder="רחוב "
          placeholder={isLoadingStreets ? "טוען נתונים..." : ""}
          {...register("street", {
            validate: (value) => isValidStreet(streetRef.current.value),
          })}
          ref={streetRef}
          onInput={() => handleSearchStreet(streetRef.current.value)}
        />
        <datalist id="streets">
          {streets !== undefined &&
            streets.map((street: any) => (
              <option value={street.שם_רחוב} key={street.שם_רחוב}>
                {street.שם_רחוב}
              </option>
            ))}
        </datalist>
        <span>{errors?.street && "יש לבחור רחוב מתוך הרשימה"}</span>
      </p>
      <p className="halfWidth">
        <label>מס' חשבונית:</label>
        <input
          id="invoicingNumber"
          type="text"
          disabled
          defaultValue={details?.number}
        />
      </p>
      <p className="halfWidth">
        <label> סכום:</label>
        <input
          id="total"
          type="text"
          disabled
          defaultValue={`${details?.total.toFixed(2)} ₪`}
        />
      </p>

      <p className="halfWidth">
        <label> מועד חשבונית:</label>
        <input id="date" type="text" disabled defaultValue={date} />
      </p>

      <p className="halfWidth">
        <label htmlFor="">שעת קניה:</label>
        <input id="branch" type="text" disabled defaultValue={time} />
      </p>

      <div>
        <p className="checkboxArea">
          <label>
            <input
              id="isEighteen"
              type="checkbox"
              {...register("isEighteen", { required: true })}
            />
            אני מצהיר/ה שאני מעל גיל 18
          </label>
          <span> {errors?.isEighteen && "נא אשר/י את גילך"}</span>
        </p>
        <p className="checkboxArea">
          <label>
            <input
              id="isAgreeAds"
              type="checkbox"
              {...register("isAgreeAds", { required: true })}
            />
            אני מאשר/ת קבלת דיוור ו/או חומר פירסומי באימייל או במסרוני SMS לנייד
          </label>
          <span> {errors?.isAgreeAds && " יש לאשר דיוור"}</span>
        </p>
        <p className="checkboxArea">
          <label>
            <input
              id="isAgreeTerms"
              type="checkbox"
              onClick={() => {
                if (isAgreeTerms) setIsAgreeTerms(!isAgreeTerms);
                else {
                  setOpen(true);
                }
              }}
              {...register("isAgreeTerms", {
                required: true,
              })}
              checked={isAgreeTerms}
            />
            קראתי את תקנון ההגרלה ואת מדיניות הגנת הפרטיות ואני עומד/ת בתנאי
            ההשתתפות
            <a
              onClick={() => {
                setOpen(true);
              }}
            >
              תקנון והגנת פרטיות
            </a>
          </label>
          <span>
            {errors?.isAgreeTerms &&
              "נא הקש על הקישור לעיל לצורך קריאת התקנון ואישור"}
          </span>
        </p>
      </div>
      <div className="btn">
        {/* <p> */}
        <div>
          <button type="submit">שליחה </button>
        </div>
        <div>
          <button type="button" onClick={onEndClick}>
            יציאה
          </button>
        </div>
        {/* </p> */}
      </div>
      <ScrollDialog
        open={open}
        onClose={setOpen}
        terms={<TermsAndConditions />}
        setIsAgreeTerms={setIsAgreeTerms}
      />
      <AlertDialog
        isAlertOpen={isOpenAlertDialog}
        setIsAlertOpen={setIsOpenAlertDialog}
        title="שים לב: הליך ההרשמה לא הסתיים"
        body="יש להקיש שליחה"
      />
    </form>
  );
};

export default RegistrationForm;
