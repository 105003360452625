import axios from "axios";
import { ITransactionRequest } from "../models/types";

const API_URL = process.env.REACT_APP_API_URL;

export const postVerifyTransaction = (payload: ITransactionRequest) => {
  try {
    return axios.post(`${API_URL}/transaction-verification`, payload);
  } catch (err) {
    throw err;
  }
};
export const postCreateCards = (payload: ITransactionRequest) => {
  try {
    return axios.post(`${API_URL}/transaction-create`, payload);
  } catch (err) {
    console.log("err api", err);
    throw err;
  }
};
