import "./DashboardPage.css";
import Navbar from "../navbar/Navbar";
import WinTable from "../table/Table";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Typography,
  FormHelperText,
  Tooltip,
} from "@mui/material";
import { ScatterPlot } from "@mui/icons-material";
import { useEffect, useState } from "react";
import MoreInfoModal from "../MoreInfoModal";
import Badge from "@mui/material/Badge";
import moment from "moment-timezone";
import IRaffle from "../../models/IRaffle";
import { RiFileExcel2Fill } from "react-icons/ri";
import React from "react";
import AreYouSureToCancel from "../modals/AreYouSureToCancel";
import { getRaffles } from "../../api/RafflesApi";
import {
  getWins,
  makeRaffle,
  cancelWin as cancelWinApi,
} from "../../api/WinsApi";
import { TextFieldWithIcon } from "../GenericTextInput";
import AreYouSureToMakeRaffle from "../modals/AreYouSureToMakeRaffle";
import { IGeneralProps } from "../../models/IGeneralProps";
import { SnackbarType } from "../../enums/SnackbarType";
import UnAuthorizedPage from "./UnAuthorizedPage";
import { IWin } from "../../models/IWin";
import axios from "axios";
import { IDetailedWin } from "../../models/IWinForExcel";
import { convertWinsForExcel } from "../../logic/ConvertWinsForExcel";
import catchFunction from "../utils/CatchFunction";
import { getDateWithoutTime } from "../../logic/ToDateFormat";
import { MoreInfoAboutRaffle } from "../dashboardComponents/MoreInfoAboutRaffle";

export default function DashboardPage(props: IGeneralProps) {
  const _export: any = React.useRef(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const [moreInfo, setIsMoreInfoOpen] = useState<IDetailedWin | null>(null);

  const [rows, setRows] = useState<IDetailedWin[]>([]);

  const [winForCancel, setWinForCancel] = useState<IDetailedWin | null>(null);

  const [isMakeRaffleModalOpen, setIsMakeRaffleModalOpen] =
    useState<boolean>(false);

  const [raffles, setRaffles] = useState<IRaffle[]>([]);

  const [selectedRaffle, setSelectedRaffle] = useState<IRaffle | null>(null);

  function createRows(rowsFromApi: IWin[]) {
    rowsFromApi.map((win) => (win.isSelected = win.card == null));

    setRows(convertWinsForExcel(rowsFromApi));
  }

  async function getAndSetWins(raffleId: number) {
    getWins(raffleId)
      .then((response: any) => createRows(response.data))
      .catch((response) =>
        catchFunction({ error: response, setSnackbar: props.setSnackbar })
      )
      .finally(() => props.setLoading({ open: false }));
  }

  function getCloserRaffle(): IRaffle | null {
    let closerRaffle = raffles.filter(
      (cell) => !moment(cell.raffle?.raffleDate).isBefore(new Date(Date.now()))
    );
    return closerRaffle[0] || null;
  }

  useEffect(() => {
    if (selectedRaffle?.raffle?.id) {
      setRows([]);
      getAndSetWins(selectedRaffle.raffle?.id);
    }
  }, [selectedRaffle]);

  useEffect(() => {
    if ((!raffles[0] || !selectedRaffle) && props.user?.rafflePermission) {
      props.setLoading({ open: true });
    } else {
      props.setLoading({ open: false });
    }
  }, [raffles, rows]);

  useEffect(() => {
    getAndSetRaffles();
  }, []);
  useEffect(() => {
    if (raffles[0]) {
      setSelectedRaffle(
        getCloserRaffle() ? getCloserRaffle() : raffles[raffles.length - 1]
      );
    }
  }, [raffles]);

  async function getAndSetRaffles() {
    if (axios.defaults.headers.common["Authorization"]) {
      console.log("getAndSetRaffles 111");
      getRafflesAndCloseLoading();
    } else {
      setTimeout(() => {
        console.log("getAndSetRaffles 222");

        getRafflesAndCloseLoading();
      }, 2000);
    }
  }
  async function getRafflesAndCloseLoading() {
    getRaffles()
      .then((response: any) =>
        setRaffles(
          response.data.sort((a: any, b: any) => {
            if (
              moment(a.raffle?.raffleDate).isBefore(
                moment(b.raffle?.raffleDate)
              )
            ) {
              return -1;
            } else if (
              moment(a.raffle?.raffleDate).isAfter(moment(b.raffle?.raffleDate))
            ) {
              return 1;
            } else {
              return 0;
            }
          })
        )
      )
      .catch((response) =>
        catchFunction({ error: response, setSnackbar: props.setSnackbar })
      )
      .finally(() => props.setLoading({ open: false }));
  }

  const [searchText, setSearchText] = useState<string>("");

  function cancelWin(win: IDetailedWin | null, cancelNote?: string) {
    props.setLoading({ open: true, message: "הזכיה בביטול..." });

    setWinForCancel(null);

    cancelWinApi(win?.winId, !win?.isCanceled, cancelNote)
      .then(async (response) => {
        if (selectedRaffle?.raffle?.id) {
          setRows([]);
          await getAndSetWins(selectedRaffle?.raffle?.id);
        }
        props.setSnackbar({
          open: true,
          type: SnackbarType.info,
          message: "הזכייה בוטלה",
        });
      })
      .catch((response) => {
        catchFunction({ setSnackbar: props.setSnackbar, error: response });
      })
      .finally(() => {
        props.setLoading({ open: false });
      });
  }

  async function makeRaffleAndSet() {
    let array: number[] = rows
      .filter((row) => row.isSelected === true)
      .map((row) => row.rafflePrizeId);
    // if(array[0]){
    makeRaffle(array)
      .then(async (response) => {
        if (selectedRaffle?.raffle?.id) {
          setRows([]);
          await getAndSetWins(selectedRaffle?.raffle.id);
        }
        props.setSnackbar({
          open: true,
          type: SnackbarType.success,
          message: "הגרלה בוצעה בהצלחה",
        });
      })
      .catch((response) => {
        catchFunction({ setSnackbar: props.setSnackbar, error: response });
      })
      .finally(() => {
        setIsMakeRaffleModalOpen(false);
        props.setLoading({ open: false });
      });
  }

  function onMakeRaffleClick() {
    props.setLoading({ open: true, message: "ההגרלה מתבצעת..." });
    makeRaffleAndSet();
  }
  function raffleDateIsAfterToday(): boolean {
    return moment(selectedRaffle?.raffle?.raffleDate).isAfter(
      new Date(Date.now())
    );
  }

  return (
    <div
      className="dashboard-page"
      dir="rtl"
      style={{ backgroundColor: "#F0F8FF" }}
    >
      {!props.user?.rafflePermission ? (
        <UnAuthorizedPage />
      ) : (
        <>
          <Navbar
            searchText={searchText}
            setSearchText={setSearchText}
            generalProps={props}
          />
          <div
            style={{
              height: "8vh",
              paddingTop: "25px",
              paddingBottom: "5px",
              display: "flex",
              justifyContent: "space-between",
              border: "none",
              alignItems: "center",
              paddingRight: "30px",
              paddingLeft: "30px",
            }}
          >
            <div style={{ display: "flex", gap: "20px" }}>
              {selectedRaffle && (
                <TextFieldWithIcon
                  variant="outlined"
                  value={selectedRaffle}
                  label="מועד הגרלה"
                  color="primary"
                  select={
                    <Select
                      labelId="demo-simple-select-label"
                      style={{ fontSize: "20px" }}
                      sx={{
                        fontWeight: "bold",
                        fontFamily: "Alef Hebrew, sans-serif",
                        color: "info.dark",
                      }}
                      color="primary"
                      size="medium"
                      id="demo-simple-select"
                      value={selectedRaffle}
                      label="מועד הגרלה"
                      onChange={(value: any) =>
                        setSelectedRaffle(value.target.value)
                      }
                    >
                      {raffles.map((raffle) => (
                        // @ts-ignore - necessary to load object into value
                        <MenuItem
                          sx={{
                            fontWeight: "bold",
                            color: "info.dark",
                            justifyContent: "center",
                            fontSize: "18px",
                            fontFamily: "Alef Hebrew, sans-serif",
                          }}
                          value={raffle}
                          key={raffle?.raffle?.id}
                        >
                          {getDateWithoutTime(raffle?.raffle?.raffleDate)}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                />
              )}

              <Badge
                badgeContent={`${
                  selectedRaffle?.data?.cardsQuantity
                } כרטיסים | ${
                  rows.filter((row) => row.isSelected).length > 0
                    ? `${rows.filter((row) => row.isSelected).length} פרסים `
                    : "לא נבחרו פרסים"
                }`}
                color={"secondary"}
                variant="standard"
                overlap="rectangular"
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
              >
                <Tooltip
                  title={
                    <span
                      style={{
                        direction: "rtl",
                        fontFamily: "Alef Hebrew, sans-serif",
                      }}
                      dir="rtl"
                    >
                      לביצוע הגרלה לחץ כאן!
                    </span>
                  }
                >
                  <Button
                    style={{
                      gap: "10px",
                      fontSize: "26px",
                      fontWeight: "bold",
                      width: "300px",
                    }}
                    variant="contained"
                    fullWidth={false}
                    disabled={rows.filter((row) => row.isSelected).length < 1}
                    size="large"
                    endIcon={<ScatterPlot />}
                    onClick={() => setIsMakeRaffleModalOpen(true)}
                    color="primary"
                  >
                    בצע הגרלה!
                  </Button>
                </Tooltip>
              </Badge>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <MoreInfoAboutRaffle raffle={selectedRaffle} />
              <Box
                sx={{
                  borderRight: "1px solid",
                  borderColor: "rgba(224, 224, 224, 1)",
                  paddingRight: 0.3,
                  marginRight: 0.3,
                }}
              >
                <Tooltip
                  title={
                    <span
                      style={{
                        direction: "rtl",
                        fontFamily: "Alef Hebrew, sans-serif",
                      }}
                      dir="rtl"
                    >
                      ייצא לאקסל
                    </span>
                  }
                >
                  <IconButton
                    sx={{ fontSize: "20px", paddingRight: 2 }}
                    color="success"
                    size="large"
                    onClick={() => excelExport()}
                  >
                    <RiFileExcel2Fill />
                  </IconButton>
                </Tooltip>
              </Box>
            </div>
          </div>
          {rows[0]?.rafflePrizeId && (
            <WinTable
              rows={rows}
              setRows={setRows}
              searchText={searchText}
              setOpenMoreInfo={setIsMoreInfoOpen}
              exportToExcel={_export}
              onCancelClick={(row: any) => setWinForCancel(row)}
            />
          )}
          <MoreInfoModal
            isOpen={moreInfo != null}
            onClose={() => setIsMoreInfoOpen(null)}
            info={moreInfo}
          />
          <AreYouSureToCancel
            row={winForCancel}
            onNo={() => setWinForCancel(null)}
            setRow={setWinForCancel}
            onYes={(cancelNote: string) => cancelWin(winForCancel, cancelNote)}
            generalProps={props}
          />
          <AreYouSureToMakeRaffle
            isOpen={isMakeRaffleModalOpen}
            onNo={() => setIsMakeRaffleModalOpen(false)}
            onYes={() => onMakeRaffleClick()}
            amountInThisRaffle={rows.filter((row) => row.isSelected).length}
          />
        </>
      )}
    </div>
  );
}
