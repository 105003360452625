import { Box, Typography } from '@mui/material';
import YochananofLogoComponent from '../utils/YochananofLogoComponent';
import { IGeneralProps } from '../../models/IGeneralProps';
import Navbar from '../navbar/Navbar';
import { NavigateTo } from '../../enums/NavigateTo';
import { useEffect, useState } from 'react';

interface IProps {
    over?: boolean;
    title: string;
    body: JSX.Element;
    generalProps?: IGeneralProps
}

export default function GenericInputPage(props: IProps) {

    function getIsMobile():boolean{
        return window.innerWidth <= 768;
    }


    const [isMobile, setIsMobile] = useState(getIsMobile());

    const handleWindowSizeChange = () => {
      setIsMobile(getIsMobile());
    };
  
    useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
      };
    }, []);
    

    return (
        <div style={{ backgroundColor: "#F0F8FF",display:"flex", flexDirection:"column",width:"100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
             {props.generalProps &&
                    window.location.pathname != NavigateTo.Login &&
                    <Navbar
                        searchText={""}
                        setSearchText={() => null}
                        generalProps={props.generalProps}
                    />}

            <div style={{ display: "flex", height: "100%",width:"100%", alignItems: "center", justifyContent: "center" }}>
                               <div style={{ alignItems: "center", width:"100%", }}>
                    {(!isMobile || window.location.pathname == NavigateTo.Login) && <YochananofLogoComponent style={{ height: "70px", paddingBottom: "25px" }} />}
                    <div style={{ display: "flex", justifyContent: "center",width:"100%", alignItems: "center" }}>
                        <Box
                            sx={{
                                fontSize: isMobile ? 20 : 40,
                                gap: "12px",
                                display: "grid",
                                fontWeight: "bold",
                                padding: 5,
                                width: 500,
                                // maxWidth:"75%",
                                rowGap: 3,
                                direction: "rtl",
                                // height: "auto",
                                // backgroundColor: 'info.light',
                                border: isMobile ? 0 : 1,
                                borderColor: "primary.main",
                                borderRadius: 6,
                                '&:hover': {
                                    opacity: props.over ? [0.9, 0.8, 0.7] : [],
                                },
                            }}
                        >
                            <Typography
                                sx={{ flex: '1 1 100%', fontSize: isMobile ? '35px' : '43px', fontWeight: "bold", fontFamily: "Alef Hebrew, sans-serif" }}
                                color="info.dark"
                            >
                                {props.title}
                            </Typography>
                            {props.body}
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    )
}
