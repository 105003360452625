import moment from "moment-timezone";

export function getDateAndTime(data?: Date): string {
  return data
    ? moment(data).tz("Asia/Jerusalem").format("HH:mm YYYY, MMM D")
    : "";
}
export function getDateWithoutTime(date?: Date): string {
  return date ? moment(date).tz("Asia/Jerusalem").format("MMM D, YYYY") : "";
}
