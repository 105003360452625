import GenericModal from "./GenericModal";
import { Button } from "@mui/material";
import { TextFieldWithIcon } from "../GenericTextInput";
import { EditNote } from "@mui/icons-material";
import { useState } from "react";
import { IGeneralProps } from "../../models/IGeneralProps";
import { SnackbarType } from "../../enums/SnackbarType";
import { IDetailedWin } from "../../models/IWinForExcel";

interface IProps {
  row: IDetailedWin | null;
  onYes: any;
  onNo: any;
  setRow: any;
  generalProps: IGeneralProps;
}

export default function AreYouSureToCancel(props: IProps) {
  const [cancelNote, setCancelNote] = useState<string>("");
  const [isNotError, setIsNoteError] = useState<boolean>(false);

  function closeModal() {
    setCancelNote("");
    setIsNoteError(false);
    props.onNo();
  }

  function setCancelNoteFromInput(event: any) {
    setCancelNote(event.target.value);
    if (isNotError) {
      setIsNoteError(false);
    }
  }
  function noteNoteNotValid() {
    setIsNoteError(true);
    props.generalProps.setSnackbar({
      open: true,
      type: SnackbarType.error,
      message: "בעת ביטול, חובה לפרט את סיבת הביטול",
    });
  }
  function noteIsValid() {
    props.onYes(cancelNote);
    closeModal();
  }

  function onCancelClick() {
    cancelNote.length < 5 ? noteNoteNotValid() : noteIsValid();
  }

  return (
    <GenericModal
      isOpen={props.row != null}
      onClose={() => closeModal()}
      body={
        <div>
          <div style={{ fontSize: "25px" }}>
            {`האם אתה בטוח שברצונך לבטל את הזכייה של ${
              props.row?.customerName
            } ב${props.row?.prizeName}?`}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "15px",
                paddingLeft: "30px",
                paddingRight: "30px",
              }}
            >
              <TextFieldWithIcon
                fullWidth={true}
                error={isNotError}
                color="primary"
                required={true}
                variant="outlined"
                label="סיבת הביטול"
                helperText={isNotError ? "יש להוסיף הערה מפורטת" :"במידה וברצונך לבטל, עליך לציין את סיבת הביטול"}
                endIconComponent={
                  <EditNote color={isNotError ? "error" : "primary"} />
                }
                value={cancelNote}
                onChange={(value: any) => setCancelNoteFromInput(value)}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "25px",
              marginTop: "20px",
            }}
          >
            <Button
              sx={{
                fontFamily: "Alef Hebrew, sans-serif",
                fontWeight: "bold",
                fontSize: "20px",
              }}
              variant="contained"
              color="error"
              size="large"
              onClick={() => onCancelClick()}
            >
              כן, בטל
            </Button>
            <Button
              sx={{
                fontFamily: "Alef Hebrew, sans-serif",
                fontWeight: "bold",
                fontSize: "20px",
              }}
              variant="contained"
              color="success"
              size="large"
              onClick={() => closeModal()}
            >
              לא, אל תבטל
            </Button>
          </div>
        </div>
      }
    />
  );
}
