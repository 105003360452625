import { NavigateTo } from "../../enums/NavigateTo";
import { SnackbarType } from "../../enums/SnackbarType";

interface IProps {
    setSnackbar: ({ open, type, message }: { open: boolean, type?: SnackbarType, message?: string }) => void;
    error: any;
}

export default function catchFunction(props: IProps) {
    console.error(props.error);
    if (props.error.statusCode == 401 || props.error.response?.status == 401) {
        window.location.pathname = `${NavigateTo.Login}`
        sessionStorage.setItem('token', "");
        sessionStorage.setItem('user', "");
    }
    props.setSnackbar({
        open: true,
        type: SnackbarType.error,
        message: props.error?.response?.data?.message || props.error?.message || "ארעה שגיאה",
    });
}