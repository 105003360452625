import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { SnackbarType } from '../../enums/SnackbarType';
import Slide from '@mui/material/Slide';


interface IProps {
    snackbarState: {open: boolean,type: SnackbarType, message: string }
    setSnackbar: ({open,type, message}:{open: boolean,type?: SnackbarType, message?: string })=>void
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackbarAlert(props: IProps) {


    return (
        <Stack spacing={2} sx={{ width: '100%' }}>

            <Snackbar
                anchorOrigin={{  vertical: 'top', horizontal: 'right'}}
                TransitionComponent={Slide}
                open={props.snackbarState.open} autoHideDuration={5000} onClose={() => props.setSnackbar({open:false})}>
                <Alert onClose={() => props.setSnackbar({open:false})} severity={props.snackbarState.type} sx={{ width: '100%', fontSize: "18px", direction: "ltr" ,  fontFamily: "Alef Hebrew, sans-serif" , alignItems:"center"}}>
                    <span style={{ fontFamily: "Alef Hebrew, sans-serif" }} dir='rtl'>{props.snackbarState.message}</span>
                </Alert>
            </Snackbar>
        </Stack>
    );
}