import React from "react";
import "./termsStyle.scss";

const TermsAndConditions = () => {
  return (
    <div className="terms">
      <p>תאריך עדכון: 4.5.2023</p>
      <h2>תקנון הגרלה "קניתי זכיתי ביוחננוף" – יוני - יולי 2023</h2>
      <ol>
        <li>
          כללי:
          <ol>
            <li>
              מטרת התקנון שלהלן, שמהווה חוזה לכל דבר ועניין, הינה הסדרת תנאי
              ההשתתפות והזכייה של המשתתף בהגרלה.
            </li>
            <li>
              ההשתתפות בהגרלה מבטאת ומהווה הסכמה לכל הוראה מהוראותיו של תקנון
              זה.
            </li>
            <li>
              תקנון זה מנוסח בלשון זכר, ומטעמי נוחות בלבד. על כן, כל האמור
              בתקנון זה בלשון זכר מתייחס גם לנקבה ולהיפך. וכל האמור בלשון יחיד,
              מתייחס גם לרבים ולהיפך.
            </li>
            <li>
              ההשתתפות בהגרלה מוצעת לכל אדם שייחשב "משתתף" בכפוף להגדרתו בתקנון
              זה.
            </li>
            <li>
              אין באמור לעיל על מנת לגרוע מזכותה של עורכת ההגרלה או מי מטעמה
              להוסיף נספחים לתקנון זה ו/או לעדכנו ככל שיידרש על ידה ו/או על-ידי
              הגורמים האחראים לאספקת אחד מן הפרסים נשוא מבצע זה, והכל בהתאם
              לשיקול דעתה הבלעדי.
            </li>
          </ol>
        </li>
        <li>
          הגדרות: למונחים הבאים בתקנון תהא ההגדרה המפורטת לצידם להלן:
          <ol>
            <li>
              "ההגרלה" – שלוש (3) הגרלות הנערכות ע"י עורכת ההגרלה ובשם "קניתי
              זכיתי ביוחננוף" בהתאם להוראות תקנון זה, ובהתאם להוראות ההיתר הכללי
              לעריכת הגרלות לפרסומת מסחרית לפי חוק העונשין, התשל"ז-1977.
            </li>
            <li>
              "עורכת ההגרלה" - חברת מ. יוחננוף ובניו (1988) בע"מ ח"פ 511344186,
              שמשרדיה נמצאים ברח' מוטי קינד 10, רחובות, 7638519.
            </li>
            <li>
              "עוזר עורכת ההגרלה" – ריטל אינוביישן בע"מ, ח"פ 516233491, שמשרדיה
              ברחוב האומנים 14 תל אביב, טל: 0546620593.
            </li>
            <li>
              {" "}
              "תקופת ההגרלה" - ההגרלה תערך כאמור בדרך של שלוש (3) הגרלות בשלושה
              מועדים שונים, אשר הזכאות להשתתף בכל אחת מהן תהא בהתאם למפורט
              בתקנון זה להלן, ובסך הכל לאורך תקופה כוללת החל מיום ה-1.6.2023
              בשעה 07:00 ועד ליום ה-13.7.2023 בשעה 23:59, והכל בכפוף לתנאים
              ולמועדים כפי שיפורטו בתקנון זה. עורכת ההגרלה שומרת לעצמה הזכות
              לקצר ו/או להאריך ו/או לשנות את תקופת ההגרלה הנידונה ואף לבטלה
              בהתאם לשיקול דעתה הבלעדי, ולא תשמע כל טענה לעניין זה. יובהר כי
              ניתן יהא לרכוש בסניפי עורכת הגרלה רק בשעות הפעילות, כאשר האמור לא
              יקנה זכות לרכוש במועדים בהם מי מסניפי עורכת ההגרלה סגורים וכי לא
              תהא כל טענה או דרישה למשתתף פוטנציאל מקום בו תקופת ההגרלה טרם
              הסתיימה, אך סניף של עורכת ההגרלה יהיה סגור, ובמקרה כאמור תקופת
              ההגרלה באותו סניף תסתיים במועד בו אותו סניף נסגר.
            </li>
            <li>
              "מקום עריכת ההגרלה" – משרדי עורכת ההגרלה ברח' מוטי קינד 10,
              רחובות.
            </li>
            <li> "אתר האינטרנט של עורכת ההגרלה" – www.yochananof.co.il.</li>
            <li>
              {" "}
              "משתתף" – כל מי שמתקיימים לגביו כל התנאים להלן:
              <ol>
                <li>
                  כל אדם שגילו מעל 18 שנים וכשיר לפעולות משפטיות, כהגדרת מונחים
                  אלו בחוק הכשרות המשפטית והאפוטרופסות, התשכ"ב-1962, ושפעל בהתאם
                  להוראות תקנון זה.
                </li>
                <li>
                  מי שרכש בחנות ו/או במסגרת שירותי הפיק אפ, ביקש לקבל וקיבל,
                  קבלה מזכה (דיגיטלית), כהגדרת מונח זה להלן.
                </li>
                <li>
                  מי שמילא את כל הפרטים הנדרשים כפי שמופיעים בטופס השתתפות,
                  כהגדרת מונח זה להלן, לרבות אישור תקנון ומדיניות ההגרלה, כמפורט
                  בטופס כאמור, וזאת עד המועד הרלוונטי להגרלה כאמור בתקנון זה.
                </li>
                <li>
                  {" "}
                  מי שלאחר שמילא את פרטיו ושלח את טופס ההשתתפות, קיבל מספר מזכה,
                  כהגדרת מונח זה להלן.
                </li>
                <li>
                  {" "}
                  המשתתף ייתן את אישורו ואת הסכמתו להיכלל במאגר הדיוור של עורכת
                  ההגרלה, והכל בהתאם להוראות חוק התקשורת (בזק ושידורים), תשמ”ב –
                  1982.
                </li>
                <li>
                  יובהר כי מספר המשתתפים וכמות המספרים המזכים אינה מוגבלת, וכי
                  לכל משתתף יכול להיות מספר שאינו מוגבל של מספרים מזכים, בהתאם
                  לכמות הרכישות שלו בסניפי עורכת ההגרלה וקבלת קבלה מזכה כאמור
                  בתקנון זה, ולפיכך סיכויי הזכייה אינם ידועים לעורכת ההגרלה.
                </li>
              </ol>
            </li>
            <li>
              {" "}
              "אינו רשאי להשתתף" –
              <ol>
                <li>
                  כל גוף שאיננו אדם פרטי אינו רשאי להשתתף בהגרלה זו, לרבות ולא
                  רק חברות ו/או קבוצות ו/או התארגנויות.
                </li>
                <li>
                  המפקח, עורכת ההגרלה, שותפיה, מנהליה, זכייניה, עובדיכל אלה ובני
                  משפחותיהם אינם רשאים להשתתף בהגרלה. לעניין סעיף זה "בני משפחה"
                  יהוו בן/בת זוג, הורה, ילדים, אחים ואחיות.
                </li>
              </ol>
            </li>
            <li>
              {" "}
              "המפקח" – עו"ד צחי נחום, מרח' מוטי קינד 10 רחובות, טל'
              076-8170401.
            </li>
            <li>
              {" "}
              "ועדת ההגרלה" – ועדה הכוללת שני נציגים של המפקח, ביניהם המפקח
              בעצמו, אשר תתכנס על פי הצורך במשרדי המפקח.
            </li>
            <li>
              "קבלה מזכה" –
              <ol>
                <li>
                  חשבונית רכישה מאת עורכת ההגרלה ששוויה (לבדה) לכל הפחות 200 ₪,
                  אשר תתקבל באחת מהדרכים הבאות: (1) חשבונית דיגיטלית שנשלחה
                  למשתתף במסרון לטלפון הסלולרי שהזין, בסמוך לקנייה באחת מחנויות
                  עורכת ההגרלה או (2) חשבונית שיקבל לקוח שביצע הזמנת "פיק אפ"
                  באמצעות האתר או באפליקציה. החשבונית תישלח ללקוח באמצעות מסרון
                  לאחר ביצוע החיוב בפועל עבור ההזמנה. יובהר כי צירוף חשבוניות
                  שהתקבלו ברכישות שונות, כאשר כל אחת מהן בנפרד אינה בסך של 200
                  ש"ח לפחות כאמור, לא תחשב לקבלה מזכה בהתאם לתנאי תקנון זה.
                </li>
                <li>
                  יובהר כי השתתפות בהגרלה מחייבת קבלת קבלה מזכה, ולא תהא לכל
                  חשבונית מודפסת או אחרת, שאינה קבלה מזכה, כהגדרתה בתקנון זה,
                  זכאות להשתתף בהגרלה, למעט כאמור בסעיף ‏2.11.8להלן.
                </li>
                <li>
                  תאריך הקבלה המזכה שתונפק למשתתפים אשר רכשו באמצעות אתר
                  האינטרנט של עורכת ההגרלה, יהיה מועד איסוף הזמנתם מסניפי עורכת
                  ההגרלה בפועל, כפי שהזין במועד ביצוע ההזמנה באתר האינטרנט
                  כאמור, ובלבד שאכן השלים הזמנתו. משתתף אשר ביטל הזמנה אינטרנטית
                  בהתאם להוראות כל דין, לא יהא זכאי לקבלה מזכה.
                </li>
                <li>
                  כמו כן, ככל שביצע משתתף באמצעות אתר עורכת ההגרלה הזמנה בסך של
                  200 ש"ח ומעלה, אך סכום כאמור הופחת בשל אספקה חלקית של מוצרים
                  במשקל (דוגמה שאינה ממצה- הזמינו 1 ק"ג של בשר, אך קיבלו רק 0.9
                  ק"ג ולפיכך סך ההזמנה ירד מתחת ל-200 ש"ח) או בשל חוסר במלאי
                  המוצרים שהוזמנו, ובלבד ששווי החוסר לא יעלה על 20 ₪ (דוגמה
                  שאינה ממצה- בשל חוסר במלאי מרכך כביסה שהוזמן בסניף, עמדה
                  חשבונית ההזמנה על סך 187 ₪ בלבד) יהיו זכאים לקבלה מזכה בכל
                  מקרה.
                </li>
                <li>
                  כל קנייה בסכום של 200 ש"ח תזכה את הלקוח בקבלה מזכה. כן כל
                  רכישה בסך המהווה כפולה של 200 ש"ח, יזכה אף הוא במספר מזכה
                  (כהגדרתו להלן) בהתאם לכמות כאמור. לדוגמה: רכישה ב-400 ש"ח
                  ומעלה תזכה בשני מספרים מזכים, רכישה ב-600 ש"ח ומעלה תזכה
                  בשלושה מספרים מזכים וכו'.
                </li>
                <li>
                  יובהר כי השתתפות בהגרלה מחייבת מילוי טופס השתתפות בהגרלה,
                  הכולל שדות למילוי פרטי המשתתף. משתתף שקיבל קבלה מזכה יכול למלא
                  טופס השתתפות באמצעות לחיצה על קישור המופיע בחשבונית הדיגיטלית
                  ובאתר עורכת ההגרלה.
                </li>
                <li>
                  כל משתתף מאשר כי ללא מילוי של פרטים נכונים ומלאים בטופס
                  ההשתתפות, עשויה להתבטל זכייתו בפרס, ככל שעלה בגורל כמפורט
                  להלן, והכל בהתאם להחלטה הבלעדית של המפקח.
                </li>
                <li>
                  בנוסף, החברה שומרת לעצמה הזכות לנפק טופסי השתתפות גם שלא
                  באמצעות חשבונית דיגיטלית כאמור, מכל סיבה שהיא, לרבות אך לא רק,
                  עבור אוכלוסייה שמתקשה בשימוש דיגיטלי, על פי שיקול דעתה הבלעדי,
                  ומבלי לפגוע במימוש זכייה.
                </li>
              </ol>
            </li>
            <li>
              "מספר מזכה" –
              <ol>
                <li>
                  לאחר מילוי ושליחת טופס ההשתתפות, יקבל המשתתף הודעה שפרטיו
                  התקבלו הכוללת מספר מזכה להגרלה, רק מספר מזכה יאפשר השתתפות
                  בהגרלה.
                </li>
                <li>מספר מזכה יוכל לזכות בפרס אחד בלבד.</li>
              </ol>
            </li>
          </ol>
        </li>

        <li>
          הפרסים:
          <ol>
            <li>
              משתתף בהגרלה שהיה זכאי לפרס בהתאם להוראות תקנון זה, ולא מימש את
              זכייתו עד תום 30 ימים ממועד פרסום תוצאות כל אחד ממועדי ההגרלות
              כאמור בסעיף 4.3 להלן באתר האינטרנט של עורכת ההגרלה, לא יהיה זכאי
              לקבל מעורכת ההגרלה פרס חלופי ו/או פיצוי כספי ו/או זיכוי, וכן לא
              תהיה לו כל טענה בנושא.
            </li>
            <li>
              מימוש הזכייה בכל אחד מהפרסים מותנה באימות פרטי המשתתף וזכייתו.
            </li>
            <li>
              "רכב הפרס" – זכאות לקבלת מכונית אחת מסוג Jeep, דגם: New Compass
              1.3T Longitude S1. על אף האמור, לעורכת ההגרלה שמורה הזכות להחליף
              את דגם המכונית בכל רכב אחר שווה ערך לפי שיקול דעתה הבלעדי, וזאת
              ככל שלא תוכל לספק את רכב הפרס מכל סיבה שהיא.
              <ol>
                <li>
                  משתתף שעלה בגורל בכל אחת מן ההגרלות, יהיה זכאי לפרס לפי הגרלה
                  זו, והכל בהתאם למפורט בתקנון זה.
                </li>
                <li>
                  למימוש הזכייה על הזוכה לפנות לפרטי ההתקשרות של עורכת ההגרלה
                  המופיעים בקבלה המזכה ולעבור אימות נתוניו האישיים וזכייתו.
                </li>
                <li>
                  אספקת הפרס לביתו של הלקוח תהיה באחריות סמלת מוטורס בע"מ ("חברת
                  הרכב") בהתאם לפרטים שימסור לעיל ובהתאם למועדים אשר יקבעו ע"י
                  חברת הרכב, והדינים החלים על עסקת אספקה של רכב חדש מסוג זה,
                  ולעורכת ההגרלה אין כל אחריות לעניין האספקה ו/או טיב השירות
                  ו/או המוצר, ובעצם קבלת הפרס מוותר הזוכה על כל טענה ו/או דרישה
                  ו/או תביעה כנגד עורכת ההגרלה בעניין.
                </li>
                <li>
                  כן ישולם על ידי עורכת ההגרלה מס על הכנסה מהגרלה בקשר עם זכייה
                  ברכב הפרס בו עשוי להיות חייב הזוכה, ככל שיחול.
                </li>
                <li>בסה"כ יחולקו במהלך ההגרלה 3 יחידות מפרס זה.</li>
              </ol>
            </li>
            <li>
              "גריל" – זכאות למַצְלֶה המופעל באמצעות גז מבית חברת Coleman.
              <ol>
                <li>
                  למימוש הזכייה על הזוכה לפנות לפרטי ההתקשרות של עורכת ההגרלה
                  המופיעים בקבלה המזכה ולעבור אימות נתוניו האישיים וזכייתו.
                </li>
                <li>
                  אספקת הפרס לביתו של הלקוח תהיה באחריות "ניין מוצרי צריכה" בע"מ
                  בהתאם לפרטים שימסור לעיל ובהתאם למועדים אשר יקבעו ע"י "ניין
                  מוצרי צריכה" בע"מ, ולעורכת ההגרלה אין כל אחריות לעניין האספקה
                  ו/או טיב השירות ו/או המוצר, ובעצם קבלת הפרס מוותר הזוכה על כל
                  טענה ו/או דרישה ו/או תביעה כנגד עורכת ההגרלה בעניין.
                </li>
                <li>בסה"כ יחולקו במהלך ההגרלה 30 יחידות מפרס זה.</li>
              </ol>
            </li>
            <li>
              "כרטיסים נטענים" – זכאות לשובר זיכוי חד-פעמי בסכום של 500 ₪ למימוש
              בסניפיה של עורכת ההגרלה בלבד ולטובת רכישת מוצרים.
              <ol>
                <li>
                  למימוש הזכייה על הזוכה לפנות לפרטי ההתקשרות של עורכת ההגרלה
                  המופיעים בקבלה המזכה ולעבור אימות נתוניו האישיים וזכייתו
                </li>
                <li>
                  התנאים, המגבלות ותוקף השימוש בשוברי הזיכוי למימוש בסניפים יהא
                  בהתאם למידע שצורף אליהם במעמד הזכייה בהם או קבלתם.
                </li>
                <li>
                  קבלת הפרס תהיה לאחר הזכייה במועד שתקבע עורכת ההגרלה, בסניפיה
                  של עורכת ההגרלה, באופן כפי שתורה עורכת ההגרלה.
                </li>
                <li>
                  לעורכת ההגרלה תהא שמורה הזכות לאפשר או שלא לאפשר, והכל בהתאם
                  לשיקול דעתה הבלעדי, שימוש בקודי ההטבה שיינתנו כפרס זה, בדרכים
                  שאינן מנויות או מפורטות בתקנון זה, לדוגמא לשימוש בהזמנות
                  אונליין.
                </li>
                <li>
                  שימוש בכרטיסים נטענים באפיקי המכירה של החברה יהא בהתאם
                  למדיניות החברה בקשר עם כרטיסים נטענים בכל מועד בו יבקש הזוכה
                  להשתמש בכרטיסים נטענים.
                </li>
                <li>בסה"כ יחולקו במהלך ההגרלה 150 יחידות מפרס זה.</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          אופן עריכת ההגרלה –
          <ol>
            <li>
              בהגרלה ישתתף כל מי שמילא טופס השתתפות באמצעות המערכת הממוחשבת באתר
              האינטרנט של ההגרלה.
            </li>
            <li>
              הודעת קבלה של טופס השתתפות במערכת תכלול את המספר המזכה בהגרלה, ואת
              מועד ההגרלה הרלוונטית מתוך מועדי ההגרלות כמפורט להלן.
            </li>
            <li>
              ההגרלה תבוצע על ידי עוזר עורכת ההגרלה, בשלושה מועדים, ובכל אחד מהם
              יוגרל רכב פרס אחד, 10 מכשירי גריל, ו-50 כרטיסים נטענים, כמפורט
              להלן:
              <ol>
                <li>
                  קבלות מזכות בגין רכישות בסניפי עורכת ההגרלה מיום 1.6.2023 ועד
                  יום 15.6.2023 כולל, ובגינן קיבל המשתתף מספר מזכה טרם מועד
                  ביצוע ההגרלה – ההגרלה תבוצע בגינן ביום 29.6.2023;
                </li>
                <li>
                  קבלות מזכות בגין רכישות בסניפי עורכת ההגרלה מיום 16.6.2023 ועד
                  יום 29.6.2023 כולל, ובגינן קיבל המשתתף מספר מזכה טרם מועד
                  ביצוע ההגרלה – ההגרלה תבוצע בגינן ביום 13.7.2023;
                </li>
                <li>
                  קבלות מזכות בגין רכישות בסניפי עורכת ההגרלה מיום 30.6.2023 ועד
                  יום 13.7.2023 כולל, ובגינן קיבל המשתתף מספר מזכה טרם מועד
                  ביצוע ההגרלה – ההגרלה תבוצע בגינן ביום 30.7.2023.
                </li>
              </ol>
            </li>
            <li>
              ההגרלה תתבצע בנוכחות המפקח על ההגרלה או מי מטעמו, כפי שיורה המפקח.
            </li>
            <li>
              ההגרלה תהיה ממוחשבת ותבוצע באמצעות תוכנה אשר תריץ את המספרים
              המזכים המשתתפים בהגרלה. התוכנה תבחר באופן אקראי מספרים מזכים כמספר
              הפרסים באותה הגרלה, כאשר לכל מספר שייבחר יוצמד באופן ממוחשב הפרס
              בו זכה. קרי, באותה הגרלה ייבחרו הזוכים ברכב הפרס, בגרילים וכן
              בכרטיסים הנטענים, והכל בהתאם למספר הפרסים המוגרלים באותה הגרלה
              כאמור לעיל.
            </li>
            <li>כל מספר מזכה יוכל לזכות בפרס אחד בלבד.</li>
            <li>
              לאחר ביצוע ההגרלה תיערך בדיקה על ידי מפקח ההגרלה לעניין זהות הזוכה
              ברכב הפרס בהתאם למספר המזכה הזוכה וכן שאותו משתתף בעל המספר המזכה
              זכה בהגרלה אכן מילא טופס הגרלה תקין.
            </li>
            <li>
              אם ימצא כי טופס ההגרלה הזוכה איננו תקין או כי איננו עומד בתנאי
              תקנון זה, תבוצע הגרלה נוספת באותו אופן במסגרתה ימצא זוכה אחר לצרכי
              זכייה ברכב הפרס. בעניין זה תהא ההחלטה בשיקול דעתו המוחלט של המפקח
              והחלטתו תהא סופית.
            </li>
            <li>
              היה וזכאותו של זוכה בגריל או בכרטיס נטען התבטלה מהטעמים האמורים
              לעיל, לא תיערך הגרלה נוספת, ועורכת ההגרלה תהיה רשאית להעביר את
              הפרסים להגרלה הבאה או לבטלם או לפעול על פי שיקול דעתה בעניינם.
            </li>
            <li>
              יובהר כי כל משתתף יוכל לזכות ברכב פרס אחד בלבד, וככל שעלו בגורל
              מספרים מזכים של אותו משתתף (בהתאם לפרטים שמילא בטופס ההשתתפות)
              לזכייה ברכב פרס נוסף, בין בהגרלה באותו מועד בו זכה, ובין בהגרלה
              שתיערך במועד אחד, כמפורט בסעיף 4.3 לעיל, הזכייה תתבטל באופן מיידי,
              ותבוצע הגרלה נוספת באותו אופן במסגרתה ימצא זוכה אחר. סעיף כאמור לא
              יחול בקשר עם זכיה בגריל או בכרטיסים נטענים.
            </li>
            <li>
              קרי, יובהר כי ככל שתתבטל זכאותו של זוכה לרכב הפרס, בין בהתאם לאמור
              בסעיף 2.8 או 2.9 לעיל, לא יהא הזוכה ברכב הפרס המספר המזכה הבא בתור
              (הראשון שזכה בגריל), אלא תבוצע הגרלה ממוחשבת נוספת באותו המועד רק
              לעניין קביעת הזוכה ברכב הפרס.
            </li>
            <li>
              מסרים שיווקיים: עורכת ההגרלה עשויה להפיץ מסרים שיווקיים על-ידי
              דיוור הודעות דואר אלקטרוני ו/או באמצעות הודעות SMS ללקוחות וחברי
              מועדון שביקשו להירשם לדיוור זה.
            </li>
            <li>משתתף שלא עמד בכל תנאיו של תקנון זה, אינו רשאי לזכות בפרס.</li>
            <li>
              תוצאות ההגרלה הינן מוחלטות וסופיות, ולא תשמע כל טענה בעניין זה.
            </li>
          </ol>
        </li>
        <li>
          הזכייה וקבלת הפרס:
          <ol>
            <li>
              כל פרס אינו ניתן להחלפה או להמרה למזומן, וכן לא יינתן בגינו עודף
              או זיכוי כתוצאה משימוש חלקי. כן לא תתאפשר לכל זוכה לבצע המחאה של
              זכותו באיזה מן הפרסים, לרבות רכב הפרס, לצד ג' כלשהו.
            </li>
            <li>
              מובהר בזאת, כי עורכת ההגרלה שומרת לעצמה הזכות שלא לאפשר לזוכה לממש
              את הפרס בו זכה, ככל שעלה חשד למפקח, על פי שיקול דעתו הבלעדי
              והמוחלט, שהזוכה נהג במרמה ו/או בניגוד להוראות תקנון זה ו/או בניגוד
              לחוק.
            </li>
            <li>
              עורכת ההגרלה או המפקח אינם נושאים באחריות מכל סוג ומין שהוא לכל
              נזק ישיר ו/או עקיף אשר יגרם למשתתף בהגרלה ו/או לצד שלישי כתוצאה
              משימוש בפרס. כל האחריות כאמור הנה על המשתתף, ולא תשמע כל טענה ו/או
              דרישה בעניין זה.
            </li>
            <li>
              בתום תקופת ההגרלה תפרסם עורכת ההגרלה את שמות הזוכים כנדרש לפי
              הוראות הדין, ובעצם קבלת הפרס המשתתף נותן הסכמתו לפרסום שמו ומוותר
              על כל טענה ו/או דרישה ו/או תביעה בקשר לכך.
            </li>
            <li>
              עורכת ההגרלה תמסור הודעה למחזיק המספר המזכה באמצעות דוא”ל ו/או
              הודעת טקסט במסרון/WHATSAPP ו/או באמצעות שיחת טלפון ובנוסף יפורסם
              המספר המזכה הזוכה בהגרלה באתר האינטרנט של עורכת ההגרלה.
            </li>
            <li>
              הליך הגרלת המספר המזכה הזוכה בהגרלה, עשוי, לפי בחירת עורכת ההגרלה,
              להיות מצולם ומפורסם באתר האינטרנט של עורכת ההגרלה ובמדיות השונות,
              תוך שמירה על חסיון פרטיו האישיים של הזוכה בהגרלה, למעט, תמונתו עם
              רכב הפרס, שמו המלא ועיר מגוריו.
            </li>
            <li>
              בכפוף לכל דין, עורכת ההגרלה תהא רשאית לסקר ו/או לצלם ו/או לפרסם
              באמצעי התקשורת השונים ובאתר האינטרנט שלה את דבר זכייתו של זוכה
              ברכב הפרס או בגריל לרבות שמו של הזוכה, תמונתו ועיר מגוריו,
              ובהשתתפותו של הזוכה בהגרלה ואישורו של תקנון זה, מהווה את אישורו של
              הזוכה לאמור, ומהווה תנאי למימוש זכייתו.
            </li>
            <li>
              זוכה ברכב הפרס
              <ol>
                <li>
                  עם היוודע לו דבר זכייתו וכתנאי לקבלת רכב הפרס, מתחייב זוכה
                  ברכב הפרס להגיע למיקום במועד שתורה לו עורכת ההגרלה. על הזוכה
                  להציג במועד תעודה מזהה רשמית באופן פיזי, וכן לחתום על הצהרת
                  הזוכה בנוסח המצורף כנספח א' לתקנון זה ("הצהרת זוכה").
                </li>
                <li>
                  הזוכה בהגרלה מאשר כי ידוע לו שרכב הפרס הנו רכב חדש הנמסר על
                  ידי חברת הרכב והוא נמסר לו במצבו הפיזי והמכני הקיים (AS-IS)
                  כפי שיימסר על ידי חברת הרכב, ולא תהיה לו כל טענה או דרישה לאי
                  התאמה כלפי עורכת ההגרלה, אלא מול חברת הרכב.
                </li>
              </ol>
            </li>
            <li>
              זוכה בגריל
              <ol>
                <li>
                  עם היוודע לו דבר זכייתו וכתנאי לקבלת גריל, מתחייב זוכה בגריל
                  להגיע למיקום במועד שתורה לו עורכת ההגרלה. על הזוכה להציג במועד
                  תעודה מזהה רשמית באופן פיזי, וכן לחתום על הצהרת זוכה.
                </li>
                <li>
                  לפנות לחברת ניין מוצרי צריכה בע"מ עם פרטיו וכפי שתורה לו עורכת
                  ההגרלה לצורך תיאום אספקה.
                </li>
              </ol>
            </li>
            <li>
              זוכה בכרטיס נטען
              <ol>
                <li>
                  האחריות על קבלת כרטיסים נטענים, חלים על המשתתפים וקבלתם תתאפשר
                  עד המועדים האמורים בסעיף 3.1 לעיל, בקופות הראשיות בכל סניפי
                  הרשת.
                </li>
                <li>
                  על הזוכה להציג במועד תעודה מזהה רשמית באופן פיזי, וכן לחתום על
                  הצהרה לפיה מאשר קבלת כרטיס נטען בנוסח המצורף כנספח ב' לתקנון
                  זה.
                </li>
              </ol>
            </li>
            <li>
              ככל שהחליט המפקח שלא ניתן להעניק את הפרס למשתתף שעלה בגורל בשל אחת
              או יותר מהסיבות הבאות, תתבטל זכייתו זו ועורכת ההגרלה תערוך הגרלה
              נוספת באופן מיידי בין המספרים המזכים שנשארו. לעניין זה תהא החלטתו
              של המפקח סופית ומוחלטת. מובהר בזאת, כי משתתף שזכייתו התבטלה כאמור
              לא יהיה זכאי לכל פיצוי שהוא בגין ביטול זכייתו ולא תעמוד לו כל טענה
              כלפי עורכת ההגרלה. ואלה הסיבות:
              <ol>
                <li>
                  פרטיו המלאים של המשתתף אינם ברורים ולא ניתן לבררם באופן סביר.
                </li>
                <li>המשתתף השיג זכאותו לפרס בדרכי רמיה.</li>
                <li>המשתתף לא חתם על הצהרת זוכה כנדרש.</li>
                <li>זכייתו של המשתתף נפסלה בהתאם לתנאיו של תקנון זה.</li>
                <li>
                  הזוכה ויתר על זכייתו, בין במפורש ובין בדרך של דרישה להמחאת
                  זכותו (אשר הנה אסורה כאמור לעיל), או שהזוכה לא דרש את הפרס
                  בתוך 30 יום מיום ההגרלה הרלוונטית בה זכה, בין אם החברה הצליחה
                  להשיגו כאמור לעיל ובין אם לאו.
                </li>
                <li>
                  בקשר לזוכה ברכב הפרס – הזוכה לא שיתף פעולה עם עורכת ההגרלה
                  לצורך ביצוע העברת הבעלות ברכב הפרס במשרד הרישוי וכל פעולה אחרת
                  הנדרשת על פי דין לצרכי קבלת רכב.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          פרשנות:
          <ol>
            <li>
              בכל מקרה בו מתקיימת סתירה ו/או אי התאמה כלשהי בין הוראה מהוראות
              תקנון זה, לפרסומים אחרים שפורסמו בכל דרך ומכל סוג הנוגעים בדבר
              ההגרלה, תגבר הוראת תקנון זה בכל דבר ועניין. האמור יחול למעט בפרסום
              הודעה הנוגעת לשינוי בתקנון.
            </li>
            <li>
              יובהר כי הכותרות בתקנון זה משמשות לצורך הנוחות בלבד ואינן בעלות
              ערך פרשני.
            </li>
          </ol>
        </li>
        <li>
          פרטיות
          <ol>
            <li>
              בנוסף לתקנון זה, תחול על ההגרלה מדיניות הפרטיות של עורכת ההגרלה
              הזמינה
              <a
                href="https://receipts.weezmo.com/storage/customers-storage/048924d9-4eb1-47eb-8210-07e614beca6f/privacy.pdf"
                target="_blank"
              >
                בקישור זה
              </a>
              , אשר מפרטת איזה מידע נאסף במסגרת ההשתתפות בהגרלה ומהו אופן השימוש
              בו ("מדיניות הפרטיות"). החברה רשאית לעדכן ולשנות את התקנון
              ומדיניות הפרטיות מעת לעת, בהתאם לשיקול דעתה הבלעדי וללא הודעה
              מוקדמת.
            </li>
          </ol>
        </li>
        <li>
          אחריות:
          <ol>
            <li>
              התקנון נמצא בסניפי עורכת ההגרלה ובמשרדי עורכת ההגרלה, וניתן לעיין
              בו ללא תשלום ובתיאום מראש עם נציגי עורכת ההגרלה.
            </li>
            <li>
              לעורכת ההגרלה אין ולא תהיה כל אחריות בדבר עצם פנייתו ו/או איחור
              בפנייתו ו/או עיכוב בפנייתו ו/או טעות בפנייתו של המשתתף.
            </li>
            <li>
              עורכת ההגרלה אינה אחראית ולא תישא באחריות להפסקות ו/או הפרעות
              כלשהן במתן אי אלו מהשירותים האמורים בתקנון זה ו/או לנזקים כלשהם,
              מכל מין וסוג שהוא, ישירים ו/או עקיפים, לרבות הוצאות ישירות ו/או
              עקיפות, אשר ייגרמו למשתתף בגין שיבושים ו/או הפרעות כאמור.
            </li>
            <li>
              עורכת ההגרלה לא תהיה אחראית באופן כלשהו על תקלות דפוס ו/או הפצה
              ו/או כל תקלה או טעות אחרת, לרבות טעות שבגינה תפחת או תגדל כמות
              הפרסים ו/או יקוצרו תקופות המבצע. מבלי לגרוע מכלליות האמור לעיל, לא
              תהיה עורכת ההגרלה אחראית לכל נזק, מכל מין וסוג שהוא, ישיר ו/או
              עקיף, לרבות הוצאות ישירות או עקיפות, שיגרמו למאן דהוא עקב תקלה
              ו/או טעות כאמור.
            </li>
            <li>
              במקרה של תקלה ו/או שיבוש ו/או טעות ו/או כוח עליון אשר ימנע ו/או
              יפגע בקיום ההגרלה, תהיה רשאית עורכת ההגרלה, עפ"י שיקול דעתה הבלעדי
              והמוחלט, ובכפוף לאישור המפקח על ההגרלה, לבטל את המבצע ו/או ההגרלה,
              או לשנות או לקצר את תקופת המבצע ו/או לבצע כל פעולה שהיא בקשר
              למבצע, לרבות לבטל את הפרסים העומדים לחלוקה, או לנקוט בכל פעולה
              אחרת כפי שתימצא לנכון. כל פעולה כאמור תיחשב כתוספת לתקנון המבצע
              ותחייב כל משתתף במבצע ובהגרלה.
            </li>
            <li>
              למען הסר ספק, לאף משתתף בהגרלה לא תהא טענה או זכות כלשהי בקשר
              לפעולות בהן תנקוט עורכת ההגרלה עקב תקלה ו/או שיבוש ו/או טעות כאמור
              לעיל, ו/או בקשר לכל נזק ישיר ו/או עקיף, לרבות הוצאות ישירות או
              עקיפות, שייגרמו להם עקב פעולות כאמור.
            </li>
            <li>
              עורכת ההגרלה אינה אחראית לכל תקלה ו/או טעות הקשורה ו/או הנובעת
              מהזכייה בפרס ו/או ממימושו, ולא תהיה אחראית על כל נזק ישיר ו/או
              עקיף, לרבות הוצאות ישירות או עקיפות, שיגרמו למאן דהוא עקב תקלה
              ו/או טעות כאמור.
            </li>
            <li>
              אם יתברר לוועדת ההגרלה כי משתתף ניסה לשנות או להשפיע על תוצאות
              ההגרלה בכל צורה ואופן, ייפסל המשתתף ולא יהיה זכאי להשתתף בהגרלה
              ו/או לזכות בפרס כלשהו. משתתפים בהגרלה אשר יפעלו שלא בהתאם להוראות
              הדין ו/או אגב ביצוע עבירה ו/או שלא בהתאם להוראות תקנון זה ו/או
              בחוסר תום לב ו/או בתחבולה ו/או מרמה ו/או שימוש לרעה ו/או הונאה
              ו/או שימוש שלא כדין במחשב ו/או בעקיפה של כללי המבצע באופן אחר
              כלשהו או תוך הפרת תקנון המבצע או בניגוד לאחד מתנאיו או בניגוד
              להוראות הדין, על מנת להשתתף במבצע ו/או להשפיע על תוצאות ההגרלה
              ו/או לזכות בפרס, ייפסלו וזכאותם לפרס תבוטל על פי שיקול דעתה של
              עורכת ההגרלה, וכפוף לאישור המפקח על ההגרלה.
            </li>
            <li>
              ככל שיחולו שינויים במועדי מימוש הפרס, מכל סיבה שהיא, לא תהיה
              לזוכים כל טענה ו/או תביעה כלפי עורכת ההגרלה ו/או מי מטעמם בגין כך,
              אף אם שינויי מועדים אלו מנעו ממנו לממש את הפרס.
            </li>
          </ol>
        </li>
        <li>
          המפקח והפיקוח על ההגרלה
          <ol>
            <li>
              המפקח אחראי על כל דבר ועניין בנוגע למבצע ו/או לתקנון זה והוא יכריע
              בכל מחלוקת שתתגלע בקשר עם המבצע, (לרבות מחלוקת בין משתתפי המבצע
              ובין עורכת ההגרלה). החלטתו של המפקח בכל עניין שבתחום אחריותו תהא
              סופית ואינה ניתנת לערעור.
            </li>
            <li>
              ההגרלה נערכת בהתאם להוראות ההיתר הכללי לעריכת הגרלות לפרסומת
              מסחרית לפי חוק העונשין, תשל"ז – 1977.
            </li>
            <li>
              החלטת המפקח בכל הקשור בהגרלה ובזכייה הינה סופית ומחייבת לכל דבר
              ועניין והמשתתף מוותר בזאת על כל טענה ו/או דרישה ו/או תביעה בקשר עם
              הכרעת המפקח.
            </li>
            <li>
              המפקח יהיה רשאי לטפל בפניות המשתתפים, להכריע בכל מחלוקת בינם לבין
              עורכת ההגרלה, אי הבנה, ספק או כל דבר אחר ובכלל זה פרשנות התקנון,
              מבלי צורך לנמק את החלטתו.
            </li>
          </ol>
        </li>
        <li>
          שונות
          <ol>
            <li>
              עורכת ההגרלה רשאית להודיע על שינוי מבנה, תוכן, נהלים, תאריכי
              הגרלה, שלבי המבצע, הפרסים והזכיות, צורת החלוקה של הפרסים ו/או
              הזכיות וגודלם.
            </li>
            <li>לא ניתן לערער על תוצאות המבצע ו/או ההגרלות.</li>
            <li>
              המשתתף מצהיר בזאת באופן בלתי חוזר כי כל מס, ניכוי או דרישה לתשלום
              מס, מכל סוג שהוא, תהיה באחריות הזוכה ועל חשבונו בלבד, והכל בהתאם
              לדינים הרלוונטיים בקשר עם מיסוי של זכייה בהגרלה. עורכת ההגרלה תהיה
              רשאית להעביר לשלטונות המס, אם יידרש לכך עפ"י הדין, את פרטי הזוכים.
              עורכת ההגרלה לא תהיה חייבת בתשלום מס כלשהו בקשר לזכייה למעט כאמור
              במפורש בתקנון זה.
            </li>
            <li>
              בכל מקרה של סתירה או אי התאמה כלשהי בין הוראות תקנון זה לפרסומים
              אחרים כלשהם בדבר המבצע, לרבות באתר עורכת ההגרלה, תקבענה ותגברנה
              הוראות תקנון זה לכל דבר ועניין.
            </li>
            <li>
              עורכת ההגרלה שומרת לעצמה את הזכות לעשות ככל שתחליט עם הפרסים אשר
              לא חולקו לאחר מועד המבצע, בכפוף לדין.
            </li>
            <li>
              על תקנון זה וכן כל היבט אחר של עריכת ההגרלה יחולו דיני מדינת ישראל
              בלבד וכן סמכות השיפוט הבלעדית תינתן לבית המשפט המוסמך לכך במחוז תל
              אביב ובמחוז זה בלבד.
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};

export default TermsAndConditions;
