import { createTheme, ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";

interface IAbstractInputProps {
  input: JSX.Element;
}
interface ITextFieldWithoutIconProps {
  label: string;
  variant: "outlined" | "filled" | "standard";
  color: "info" | "primary" | "error" | "warning" | "success" | "secondary";
  type?: string;
  disabled?: boolean;
  required?: boolean;
  value: any;
  shrink?: boolean;
  error?: boolean;
  onChange: any;
  helperText?: string;
  fullWidth?: boolean;
  onKeyDown?: any;
  inputProps?: {};
  onInput?: any;
}
interface ITextFieldWithIconProps {
  label?: string;
  error?: boolean;
  variant: "outlined" | "filled" | "standard";
  color: "info" | "primary" | "error" | "warning" | "success" | "secondary";
  type?: string;
  shrink?: boolean;
  required?: boolean;
  disabled?: boolean;
  showPassword?: boolean;
  iconPosition?: "start" | "end";
  setShowPassword?: any;
  select?: JSX.Element;
  endIconComponent?: JSX.Element;
  startIconComponent?: JSX.Element;
  value?: any;
  onChange?: any;
  helperText?: string;
  fullWidth?: boolean;
  onKeyDown?: any;
  inputProps?: {};
  onInput?: any;
}

function AbstractInput(props: IAbstractInputProps) {
  const theme = createTheme({
    direction: "rtl", // Both here and <body dir="rtl">
  });
  // Create rtl cache
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [rtlPlugin],
  });

  return (
    <>
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>{props.input}</ThemeProvider>
      </CacheProvider>
    </>
  );
}

export function TextFieldWithoutIcon(props: ITextFieldWithoutIconProps) {
  return (
    <AbstractInput
      input={
        <TextField
          onKeyDown={props.onKeyDown}
          sx={{ textAlign: "start" }}
          required={props.required || false}
          label={props.label}
          fullWidth={props.fullWidth || false}
          type={props.type}
          variant={props.variant}
          color={props.color}
          disabled={props.disabled || false}
          error={props.error || false}
          value={props.value}
          helperText={props.helperText}
          inputProps={props.inputProps}
          onInput={props.onInput}
          onChange={props.onChange}
        />
      }
    />
  );
}
export function TextFieldWithIcon(props: ITextFieldWithIconProps) {
  return (
    <AbstractInput
      input={
        <FormControl
          error={props.error}
          variant={props.variant}
          fullWidth={props.fullWidth}
          sx={{ fontFamily: "Alef Hebrew, sans-serif" }}
        >
          <InputLabel
            color={props.color}
            sx={{ textAlign: "start", fontFamily: "Alef Hebrew, sans-serif" }}
            htmlFor="outlined-adornment-password"
            required={props.required || false}
          >
            {props.label}
          </InputLabel>
          {props.select ? (
            props.select
          ) : (
            <OutlinedInput
              onKeyDown={props.onKeyDown}
              type={
                props.type === "password"
                  ? props.showPassword
                    ? "text"
                    : "password"
                  : props.type
                  ? props.type
                  : "text"
              }
              value={props.value}
              error={props.error || false}
              onChange={props.onChange}
              fullWidth={props.fullWidth || false}
              color={props.color}
              inputProps={props.inputProps}
              onInput={props.onInput}
              sx={{ fontFamily: "Alef Hebrew, sans-serif" }}
              endAdornment={
                props.endIconComponent ? (
                  <InputAdornment position={props.iconPosition || "end"}>
                    {props.endIconComponent}
                  </InputAdornment>
                ) : null
              }
              startAdornment={
                props.startIconComponent ? (
                  <InputAdornment position={props.iconPosition || "end"}>
                    {props.startIconComponent}
                  </InputAdornment>
                ) : null
              }
              label={props.label}
            />
          )}
          {props.helperText && (
            <FormHelperText
              error={props.error}
              sx={{
                width: "100%",
                padding: "0px",
                fontFamily: "Alef Hebrew, sans-serif",
              }}
            >
              {props.helperText}
            </FormHelperText>
          )}
        </FormControl>
      }
    />
  );
}
