import axios from "axios";

const url = "https://data.gov.il/api/3/action/datastore_search";

export const getApiData = (payload: string) => {
  try {
    delete axios.defaults.headers.common["Authorization"];
    return axios.get(`${url}${payload}`);
  } catch (err) {
    throw err;
  }
};
