import * as React from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import { visuallyHidden } from "@mui/utils";
import { Delete, Info } from "@mui/icons-material";
import "../pages/DashboardPage.css";
import "./Table.css";
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup,
} from "@progress/kendo-react-excel-export";
import moment from "moment-timezone";
import { IDetailedWin } from "../../models/IWinForExcel";
import { Tooltip } from "@mui/material";
import { getDateWithoutTime } from "../../logic/ToDateFormat";

interface IProps {
  rows: IDetailedWin[];
  setRows: any;
  searchText: string;
  setOpenMoreInfo: any;
  exportToExcel: any;
  onCancelClick: (row: any) => void;
}

export default function WinTable(generalProps: IProps) {
  // const [row, setRow] = React.useState<any>([]);
  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  type Order = "asc" | "desc";

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
  // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
  // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
  // with exampleArray.slice().sort(exampleComparator)
  function stableSort<T>(
    array: IDetailedWin[],
    comparator: (a: T, b: T) => number
  ) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  interface HeadCell {
    id: keyof IDetailedWin;
    label: any;
  }

  function isAllSelected() {
    return (
      generalProps.rows.filter((row: IDetailedWin) => row.isSelected).length ==
      generalProps.rows.filter((row: IDetailedWin) => !row.cardNumber).length
    );
  }
  function changeAllSelected() {
    let copy = [
      ...generalProps.rows.filter((row: IDetailedWin) => !row.cardNumber),
    ];
    let desireValue = !isAllSelected();
    copy.map((row: any) => (row.isSelected = desireValue));
    generalProps.setRows(copy);
  }

  const headCells: readonly HeadCell[] = [
    { id: "isCanceled", label: "" },
    {
      id: "isSelected",
      label: (
        <Checkbox
          size="small"
          checked={isAllSelected()}
          onChange={() => changeAllSelected()}
        />
      ),
    },
    { id: "isCanceled", label: "" },
    { id: "prizeName", label: "פרס" },
    { id: "raffleDate", label: "תאריך הגרלה" },
    { id: "isCanceled", label: "סטטוס" },
    { id: "cardNumber", label: "קוד מזכה" },
    { id: "customerName", label: "שם לקוח" },
    { id: "customerMobilePhone", label: "טלפון" },
    { id: "transactionType", label: "סוג עסקה" },
    { id: "transactionNumber", label: "מספר הזמנה / חשבונית" },
  ];

  interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (
      event: React.MouseEvent<unknown>,
      property: keyof IDetailedWin
    ) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
  }

  function EnhancedTableHead(props: EnhancedTableProps) {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;
    const createSortHandler =
      (property: keyof IDetailedWin) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead id="table-header">
        <TableRow hover>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={"center"}
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                backgroundColor: "#F0F8FF",
                fontFamily: "Alef Hebrew, sans-serif",
              }}
              padding={"normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.id == "isSelected" || headCell.id == "isCanceled" ? (
                <div>{headCell.label}</div>
              ) : (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  interface EnhancedTableToolbarProps {
    numSelected: number;
  }

  function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { numSelected } = props;

    return (
      <Toolbar
        id="table-name"
        style={{ backgroundColor: "#F0F8FF", borderTop: "none" }}
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        <Typography
          sx={{
            flex: "1 1 100%",
            fontSize: "50px",
            fontWeight: "bold",
            paddingBottom: "5px",
            fontFamily: "Alef Hebrew, sans-serif",
          }}
          variant="h6"
          id="tableTitle"
          component="div"
          color="info.dark"
        >
          רשימת ההגרלות
        </Typography>
      </Toolbar>
    );
  }

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof IDetailedWin>("prizeName");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(1000);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IDetailedWin
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = generalProps.rows.map((n, index) => index.toString());
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - generalProps.rows.length)
      : 0;

  const visibleRows: IDetailedWin[] = React.useMemo(
    () =>
      stableSort(generalProps.rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage]
  ) as any;

  function filterSearch(row: IDetailedWin) {
    if (
      row.prizeName?.includes(generalProps.searchText) ||
      row.canceledReason?.includes(generalProps.searchText) ||
      row.customerName?.includes(generalProps.searchText) ||
      row.customerIdNumber?.includes(generalProps.searchText) ||
      new Number(row.transactionNumber)
        .toString()
        .includes(generalProps.searchText) ||
      // new Number(row.orderId).toString().includes(generalProps.searchText) ||
      new Number(row.cardNumber).toString().includes(generalProps.searchText)
    ) {
      return row;
    }
  }

  function openMoreInfo(data: any) {
    generalProps.setOpenMoreInfo(data);
  }

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer
          sx={{
            height: "70vh",
          }}
        >
          <ExcelExport
            fileName={`רשימת פרסים וזכיות - ${getDateWithoutTime(
              new Date(generalProps.rows[0]?.raffleDate)
            )}`}
            data={generalProps.rows}
            dir="rtl"
            ref={generalProps.exportToExcel}
          >
            <ExcelExportColumnGroup
              title={
                "מועד הגרלה: " +
                getDateWithoutTime(new Date(generalProps.rows[0]?.raffleDate))
              }
              headerCellOptions={{
                textAlign: "center",
                bold: true,
                color: "#FFFFFF",
                background: "#000000",
                // borderBottom: { color: "black", size: 2 }
              }}
            >
              {generalProps.rows[0] &&
                Object.keys(generalProps.rows[0])
                  .filter((cell) => cell != "isSelected")
                  .map((cell) => (
                    <ExcelExportColumn
                      field={cell}
                      title={cell}
                      headerCellOptions={{ bold: true, textAlign: "center" }}
                      cellOptions={{ textAlign: "center" }}
                    />
                  ))}
            </ExcelExportColumnGroup>
            <Table
              stickyHeader={true}
              sx={{
                height: "max-content",
              }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={generalProps.rows.length}
              />
              <TableBody>
                {visibleRows
                  .filter((row: any) => filterSearch(row))
                  .map((row, index) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={index}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell align="center">
                          <Tooltip
                            title={
                              <span
                                style={{
                                  direction: "rtl",
                                  fontFamily: "Alef Hebrew, sans-serif",
                                }}
                                dir="rtl"
                              >
                                ביטול זכייה
                              </span>
                            }
                          >
                            <IconButton
                              disabled={!row.cardNumber}
                              onClick={() => generalProps.onCancelClick(row)}
                            >
                              {row.cardNumber && !row.isCanceled && (
                                <Delete color="error" />
                              )}
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: "17px" }}>
                          <Checkbox
                            size="small"
                            disabled={!!row.cardNumber}
                            checked={row.isSelected}
                            onChange={() => {
                              visibleRows[index].isSelected = !row.isSelected;
                              generalProps.setRows([...visibleRows]);
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip
                            title={
                              <span
                                style={{
                                  direction: "rtl",
                                  fontFamily: "Alef Hebrew, sans-serif",
                                }}
                                dir="rtl"
                              >
                                מידע נוסף
                              </span>
                            }
                          >
                            <IconButton onClick={() => openMoreInfo(row)}>
                              {<Info color="info" />}
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            fontSize: "17px",
                            borderRight: "1px solid",
                            borderColor: "rgba(224, 224, 224, 1)",
                          }}
                        >
                          {row.prizeName}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            fontSize: "17px",
                            borderRight: "1px solid",
                            borderColor: "rgba(224, 224, 224, 1)",
                          }}
                        >
                          {row.raffleDate}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            fontSize: "17px",
                            borderRight: "1px solid",
                            borderColor: "rgba(224, 224, 224, 1)",
                            color: row.isCanceled
                              ? "red"
                              : row.cardNumber
                              ? "green"
                              : "",
                          }}
                        >
                          <b>
                            {!row.cardNumber
                              ? "לא הוגרל"
                              : row.cardNumber &&
                                (row.isCanceled ? "בוטל" : "פעיל")}
                          </b>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            fontSize: "17px",
                            borderRight: "1px solid",
                            borderColor: "rgba(224, 224, 224, 1)",
                          }}
                        >
                          {row.cardNumber?.toString()}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            fontSize: "17px",
                            borderRight: "1px solid",
                            borderColor: "rgba(224, 224, 224, 1)",
                          }}
                        >
                          {row.customerName ? row.customerName : ""}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            fontSize: "17px",
                            borderRight: "1px solid",
                            borderColor: "rgba(224, 224, 224, 1)",
                          }}
                        >
                          {row.customerMobilePhone
                            ? row.customerMobilePhone.substring(0, 3) +
                              "-" +
                              row.customerMobilePhone.substring(3)
                            : ""}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            fontSize: "17px",
                            borderRight: "1px solid",
                            borderColor: "rgba(224, 224, 224, 1)",
                          }}
                        >
                          {row.isManual
                            ? "נוסף ידנית"
                            : row.transactionType == "in_store"
                            ? "חנות"
                            : row.transactionType == "online"
                            ? "אתר הסחר"
                            : ""}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            fontSize: "17px",
                            borderRight: "1px solid",
                            borderColor: "rgba(224, 224, 224, 1)",
                          }}
                        >
                          {row.transactionNumber?.toString()}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </ExcelExport>
        </TableContainer>
      </Paper>
    </Box>
  );
}
