import React from 'react'
import yochananofLogo from '../../../../assets/yochananofLogo.png'

interface IProps{
    style?:{}
}

export default function YochananofLogoComponent(props:IProps) {


    return (
        <div>
            <img style={props.style } src={yochananofLogo}/>
        </div>
    )
}
